/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).ready(function() {
  $('.carousel-default .owl').each(function() {
    return $(this).owlCarousel({
      mouseDrag: false,
      touchDrag: true,
      rewindNav: $(this).data('carousel-autoplay'),
      autoHeight : true,
      lazyLoad : true,
      items: ($(this).data('carousel-desktop-items') || 2),
      itemsDesktop: [breakpoints.medium, ($(this).data('carousel-tablet-items') || 2)],
      itemsDesktopSmall: [breakpoints.small, ($(this).data('carousel-mobile-items') || 1)],
      itemsTablet: false,
      itemsTabletSmall: false,
      itemsMobile: false,
      baseWidth: $(this).innerWidth(),
      scrollPerPage: false,
      navigation: true,
      navigationText: ["&lsaquo;","&rsaquo;"],
      dotsMax: 9,
      autoPlay: ($(this).data('carousel-autoplay') === true ? 3000 : false),
      stopOnHover: $(this).data('carousel-autoplay'),
      afterInit() {
        return this.$elem.find('.owl-pagination').insertAfter(this.$elem.find('.owl-prev'));
      },
      afterMove(carousel) {
        return $(carousel).closest('.carousel').find('.carousel-tabs a').eq(this.currentItem).addClass('active').siblings().removeClass('active');
      }
    });
  });

  $('.carousel-tabs a').click(function(e) {
    e.preventDefault();
    const carousel = $(this).closest('.carousel').find('.owl').data('owlCarousel');
    carousel.goTo($(this).index());
    carousel.options.autoPlay = false;
    return carousel.stop();
  });

  return $('#my-comments .carousel-default .owl').each(function() {
    return $(this).owlCarousel({
      responsive: {
        0: { items: 1
        },
        480: { items: 2
        },
        768: { items: 3
        }
      }
    });
  });
});
