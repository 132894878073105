window._131001_InteractiveTable = function() {

    var ready = false;

    var _id;

    var _container_el;

    var _container_jq;

    var _table_container_el;

    var _table_container_jq;

    var _sort_column_label;

    var _sticky_header = false;

    var _unique_sticky_header = false;

    var _active_column_index;

    var _subheadings = false;

    var _width_enabled = false;

    var _table_offset_left;

    var _sort_order = 'asc';

    var _sort_column_functions = {};

    var _bar_columns = {}; // all values should be percs - e.g. "85%"

    var _sort_columns = {};

    var _tick_columns = {};

    var _rcmd_columns = {};

    var _hidden_columns = {};

    var _width_columns = {};

    var _column_labels = [];

    var _has_totals = false;

    var _values = [];


    var _totals = {};


    var setContextualColours = function() {
        var article_jq = $('article.article, article.clp, div.product-landing-page');
        var contexts = ['product', 'business', 'consumer', 'member'];
        for (var i = 0; i < contexts.length; i++) {
            var context = contexts[i];
            var clazz = ['page-', context].join('');
            if (article_jq.hasClass(clazz)) {
                $(['#' + _id].join(''))
                    .addClass(clazz)
                    .parent().parent().find('header').removeClass('header-general').addClass(['header-' + context].join(''));
                break;
            }
        };
    }


    var getSortObj = function(a, b) {
      var o = {};
      o.b = a.hasOwnProperty(_sort_column_label) ? a[_sort_column_label] : a;
      o.a = b.hasOwnProperty(_sort_column_label) ? b[_sort_column_label] : b;
      return o;
    };


    var sortNumeric = function(a, b) {
        var o = getSortObj(a, b);
        return o.b.replace(',', '') - o.a.replace(',', '');
    };


    var sortAlpha = function(a, b) {
        var o = getSortObj(a, b);
        return o.a.localeCompare(o.b);
    };


    var sortPerc = function(a, b) {
        var o = getSortObj(a, b);
        return o.b.replace('%', '') - o.a.replace('%', '');
    };


    var sortBool = function(a, b) {
        var o = getSortObj(a, b);
        var x = o.a.toLowerCase();
        var y = o.b.toLowerCase();
        if (x === y)
            return 0;
        else if (x === 'yes')
            return -1;
        else if (x === 'no')
            return 1;
    };

    var sortRowsBySubheading = function(sortFunction) {
      // sorts values under subheadings while keeping order
      // of subheadings

      var localValues = _values
      var subheading_indexes = []
      var rows = _has_totals
            ? _table_container_jq.find('tr').slice(1, -1)
            : _table_container_jq.find('tr').slice(1);

      // get list of indexes of subheadings
      rows.each(function(i, el) {
        if ($(el).children('.subheading').length > 0) {
          subheading_indexes.push(i)
        }
      })

      _values = []

      subheading_indexes.forEach(function (el, i) {
        _values.push([localValues.shift()])

        if (i === 0) {
          _values.push(localValues.splice(0, subheading_indexes[i+1] -1))
        } else if (i+1 === subheading_indexes.length) {
          _values.push(localValues)
        } else {
          _values.push(localValues.splice(0, (subheading_indexes[i+1] - el) - 1))
        }
      })

      // sort each sub array
      _values.map(function(el) {
        if(el.length > 1) {
          el.sort(sortFunction)

          if(_sort_order === 'asc') {
            el.reverse()
          }

          return el
        }
      })

      _values = [].concat.apply([], _values)

    }

    var getUniqueColumnValues = function(column_label) {
        var values = [];
        for (var i = 0; i < _values.length; i++) {
            var row_values = _values[i];
            var value = row_values[column_label];
            values.push(value);
        }
        values = $.unique(values);
        return values;
    };


    var getSortFunction = function(values) {
        var bool_values = { 'yes' : true, 'no' : true };
        var all_bool = true;
        var all_numeric = true;
        var all_perc = true;
        for (var i = 0; i < values.length; i++) {
            var value = values[i];
            if (!$.isNumeric(value.replace(',', '')))
                all_numeric = false;
            if (value.indexOf('%') === -1)
                all_perc = false;
            if (!bool_values.hasOwnProperty(value.toLowerCase()))
                all_bool = false;
        }
        if (all_bool)
            return sortBool;
        else if (all_perc)
            return sortPerc;
        else if (all_numeric)
            return sortNumeric;
        else
            return sortAlpha;
    };


    var convertColumnValues = function(columns, convertTdFn) {
        // regular table
        for (var i = 0; i < _column_labels.length; i++) {
            var column_label = _column_labels[i];
            if (columns.hasOwnProperty(column_label)) {
                var trs = _table_container_jq.find('table.x131001_regular_table tr');
                for (var j = 0; j < trs.length; j++) {
                    var tr = trs[j];
                    var tds = $(tr).find('td');
                    if (tds.length > 0) {
                        var td = tds[i];
                        convertTdFn(td);
                    }
                }
            }
        }
    };


    var convertPercsToBars = function() {
        convertColumnValues(_bar_columns, convertPercsToBarsFn);
    }


    var convertPercsToBarsFn = function(td) {
        var td_jq = $(td);
        var value = td_jq.text();
        if (value.match(/%/)) {
            // 67%, do nothing
        } else if (value <= 1) {
            // 0.67, convert
            value = Math.round(value * 100) + '%';
        }
        td_jq.html([
            '<div class="bar-table">',
                '<div class="bar">',
                    '<div style="width: ' + value + '"></div>',
                '</div>',
                '<div class="value">' + value + '</div>',
             '</div>'
        ].join(''));
    }


    var convertYesNoToTicks = function() {
        convertColumnValues(_tick_columns, convertYesNoToTicksFn);
    }


    var convertYesNoToTicksFn = function(td) {
        var td_jq = $(td);
        var value = td_jq.text();
        if (value.toLowerCase() === 'yes') {
            td_jq.html('<span class="x131001_tick"></span>');
        } else if (value.toLowerCase() === 'no') {
            td_jq.html('<span class="x131001_cross"></span>');
        } else if (value === '-') {
            td_jp.html('<span class="x131001_minus"></span>');
        }
    }


    var convertRcmdToIcons = function() {
        convertColumnValues(_rcmd_columns, convertRcmdToIconsFn);
    };


    var convertRcmdToIconsFn = function(td) {
        var td_jq = $(td);
        var value = td_jq.text();
        if (value.toLowerCase() === 'r') {
            td_jq.html('<span class="badge"><i class="badge--recommended"></i></span>');
        } else if (value.toLowerCase() === 'wc') {
            td_jq.html('<span class="badge"><i class="badge--worth-considering"></i></span>');
        }
    }


    var modifyTableContentsAndStructure = function() {
        convertPercsToBars();
        convertYesNoToTicks();
        convertRcmdToIcons();
        offsetTableWidth();
        alignRowHeights();
    };


    var createSort = function(table_container) {
      $(table_container).find('tbody tr:first-of-type th').each(function(i, el) {
        if(!$(el).hasClass('sortable'))
          return

        el.removeEventListener('click', sortByColumn)
        el.addEventListener('click', sortByColumn)
      })
    };


    var sortByColumn = function() {
      if(_sort_order === 'asc') {
        _sort_order = 'desc'
      } else {
        _sort_order = 'asc'
      }
      var active_column = $(this)

      _active_column_index = active_column.index()
      _sort_column_label = active_column.text();

      if (_sort_column_functions.hasOwnProperty(_sort_column_label + '__hidden')) {
          _sort_column_label += '__hidden';
      }
      sortTable();
    }


    var sortTable = function() {

      var sortFunction = _sort_column_functions[_sort_column_label];
      if(_subheadings) {
        sortRowsBySubheading(sortFunction)
      } else {
        _values.sort(sortFunction);
        if(_sort_order === 'desc') {
          _values.reverse()
        }
      }
      createTable();
    };

    var createTable = function() {

        var widthClass = ''
        // small, medium and large can be added to columns
        if(_width_enabled) {
          widthClass = 'width-enabled'
        }

        var html = ['<table class="x131001_regular_table ' + widthClass + '"><tr>'];
        for (var i = 0; i < _column_labels.length; i++) {
            var column_label = _column_labels[i];
            var classes = []
            if (_hidden_columns.hasOwnProperty(column_label)) {
              continue;
            }
            if(_sort_columns.hasOwnProperty(column_label) || _hidden_columns.hasOwnProperty(column_label + '__hidden')) {
              classes.push('sortable')
            }
            if(_width_columns.hasOwnProperty(column_label)) {
              classes.push('table-col-' + _width_columns[column_label])
            }
            html.push('<th class="' + classes.join(' ') + '">', column_label, '</th>');
        }
        html.push('</tr>');
        for (var i = 0; i < _values.length; i++) {
            var row_values = _values[i];
            html.push('<tr>');
            var numNonBlankValues = 0;
            for (var column_label in row_values) {
                if (_hidden_columns.hasOwnProperty(column_label))
                    continue;
                var value = $.trim(row_values[column_label]);
                if (value != '')
                    numNonBlankValues++;
            }
            var first = true;
            for (var column_label in row_values) {
                if (_hidden_columns.hasOwnProperty(column_label))
                    continue;
                var value = $.trim(row_values[column_label]);
                html.push('<td>', value, '</td>');
                if (numNonBlankValues == 1) {
                } else {
                    // Normal
                    var b1 = first ? '<strong>' : '';
                    var b2 = first ? '</strong>' : '';
                    first = false;
                }
            }
            html.push('</tr>');
        }
        if (_has_totals) {
            html.push('<tr>');
            for (var column_label in _totals) {
                if (_hidden_columns.hasOwnProperty(column_label))
                    continue;
                var value = _totals[column_label];
                html.push('<td>', '<strong>', value, '</strong></td>');
            }
            html.push('</tr>');
        }
        html.push('</table>');
        _table_container_jq.html(html.join(''));
        // Values of <b class="subheading">My label</b> can be added by content team
        // Will add .subheading to add applicable <td>'s
        _table_container_jq.find('.subheading').parent('td').parent('tr').children('td').addClass('subheading');
        createSort(_container_el);
        modifyTableContentsAndStructure()
        // reattach event listeners to table headings after table is rebuilt

        if(!_sticky_header) {
          // table doesn't need a sticky header if it's shorter than the window
          if(_table_container_jq.height() < screenHeight()) return

          var initStickyHeader = function()  {
            if(_sticky_header) {
              _sticky_header.remove()
            }
            var stickyHeader = createStickyHeader();
            window.addEventListener('scroll', window.debounce(stickyHeader, 10))
            window.removeEventListener('scroll', initStickyHeader, false)
          }
          window.addEventListener('scroll', initStickyHeader)
          window.addEventListener('resize', window.debounce(initStickyHeader, 500))
        }

        updateDropDownIndicators()
    }

    var updateDropDownIndicators = function() {
      if(typeof _active_column_index !== 'undefined') {

        var tableLeadRow = $(_container_el).find('tbody tr:first-of-type th')
        var stickyLeadRow = $(_sticky_header).find('tbody tr:first-of-type th')

        var active_columns = $(tableLeadRow[_active_column_index])

        if(_sticky_header) {
          active_columns = active_columns.add(stickyLeadRow[_active_column_index])
        }

        tableLeadRow.add(stickyLeadRow)
                    .removeClass('selected selected_asc selected_desc')


        active_columns.addClass('selected')

        if(_sort_order === 'asc') {
          active_columns.removeClass('selected_asc')
          active_columns.addClass('selected_desc')
        } else {
          active_columns.removeClass('selected_desc')
          active_columns.addClass('selected_asc')
        }
      }
    }

    var alignRowHeights = function () {
      var rows = _table_container_jq[0].querySelectorAll('tr')

      for (var i = 0; i < rows.length; i++) {
        var maxHeight = 0,
            children = rows[i].querySelectorAll('td, th')

        for (var j = 0; j < children.length; j++) {
          var cellHeight = children[j].offsetHeight

          if(maxHeight < cellHeight) {
            maxHeight = cellHeight
          }
        }

        rows[i].style.height = maxHeight + 'px'

        for(var k = 0; k < children.length; k++) {
          children[k].style.height = maxHeight + 'px'
        }

      }
    }

    var offsetTableWidth = function () {
      // prevent table scrolling if it's not large enough to warrant it
      var table = _container_jq.find('table')
      var num_cols = table.find('tr:first-of-type th').length
      var tableMarginLeft = parseInt(table.css('marginLeft'))
      if(num_cols < 4 && $(window).width() > 608) {
        table.width(table.width() - tableMarginLeft)
      }

      if(num_cols < 3 && $(window).width() < 608) {
        table.width(table.width() - tableMarginLeft)
      }
    }

    var screenHeight = function (){
      var w=window,d=document,e=d.documentElement,g=d.getElementsByTagName('body')[0],y=w.innerHeight||e.clientHeight||g.clientHeight;
      return y
    }

    var createStickyHeader = function() {
      var stickyHeader = _table_container_jq.clone(true, true)

      _table_offset_left = _table_container_jq.offset()['left']

      var tableEntersViewPort = _table_container_jq.offset()['top']
      var tableExitsViewPort = tableEntersViewPort + _table_container_jq.height()
      var stickyHeaderActive = false

      stickyHeader.find('th:first-of-type').css('opacity', '0')
      stickyHeader.css('opacity', '0')
      stickyHeader.find('th:first-of-type').css('position', 'absolute');
      stickyHeader.css('position', 'absolute')
      stickyHeader.css('transition', 'none')

      stickyHeader.width(_table_container_jq.width())
      stickyHeader.find('th:first-of-type').css('left', _table_offset_left)

      stickyHeader.addClass('sticky')
      stickyHeader.removeClass('x131001_interactive_table')

      _sticky_header = stickyHeader

      var stickyInitiatedScroll = false
      var tableInitiatedScroll = false

      $('body').prepend(stickyHeader)

      return function() {
        // Take the performance hit to avoid issues with screen height mutating
        // after initialization (as videos load, accordians expand etc.)
        tableEntersViewPort = _table_container_jq.offset()['top']
        tableExitsViewPort = tableEntersViewPort + _table_container_jq.height()

        var verticalScroll = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop)

        stickyHeader.css('marginLeft', _table_offset_left)
        stickyHeader.find('th:first-of-type').css('left', _table_offset_left)




        // there are 2 tables -  one only shows as a fixed header and one static
        // need to keep them in sync while scrolling each table horizontally
        // event listeners to scrolling sideways on fixed & static tables
        // updates other table's scroll only if scroll event originated from current table
        // prevents (reduces) janky double scroll updating



        stickyHeader.scroll(function(e) {
          if(!tableInitiatedScroll) {
            stickyInitiatedScroll = true
            _table_container_jq.scrollLeft(this.scrollLeft)
          } else {
            tableInitiatedScroll = stickyInitiatedScroll = false
          }

        })

        _table_container_jq.scroll(function(e) {
          if(!stickyInitiatedScroll) {
            tableInitiatedScroll = true
            stickyHeader.scrollLeft(this.scrollLeft)
          } else {
            tableInitiatedScroll = stickyInitiatedScroll = false
          }
        })


        var checkAccordianClosed = function() {
          if ($(".accordian-wrap-rm").length < 1) {
            // No accordian is implicitly open
            return false;
          }

          return _table_container_jq.closest(".accordian-wrap-rm").height() == 0
        }

        if(verticalScroll < tableEntersViewPort || verticalScroll > tableExitsViewPort && stickyHeaderActive || checkAccordianClosed()) {
          // remove and deactivate

          stickyHeader.find('th:first-of-type').css('opacity', '0')
          stickyHeader.css('opacity', '0')
          stickyHeader.find('th:first-of-type').css('position', 'absolute');
          stickyHeader.css('position', 'absolute')

          stickyHeader.find('th:first-of-type').css('left', '0')
          _table_container_jq.find('th:first-of-type').css('zIndex', '5')

          stickyHeader.css('top', _table_container_jq.offset()['top'])
          stickyHeader.css('pointer-events', 'none')
          stickyHeaderActive = false

          return
        }


        var checkAccordianOpen = function() {
          if ($(".accordian-wrap-rm").length < 1) {
            // No accordian is implicitly open
            return true;
          }
          return _table_container_jq.closest(".accordian-wrap-rm").height() > 0
        }

        if (verticalScroll > tableEntersViewPort && verticalScroll < tableExitsViewPort && !stickyHeaderActive && checkAccordianOpen()) {

          // stickyHeader.find('th:first-of-type').css('position', 'relative')
          stickyHeader.css('marginLeft', _table_offset_left)
          stickyHeader.find('th:first-of-type').css('left', _table_offset_left)

          _table_container_jq.find('th:first-of-type').css('zIndex', '0')

          // 1px safari offset
          stickyHeader.css('pointer-events', 'all')
          createSort(stickyHeader)
          stickyHeader.scrollLeft(_table_container_jq.scrollLeft())


          stickyHeader.find('th:first-of-type').css('position', 'fixed');
          stickyHeader.css('position', 'fixed')
          stickyHeader.find('th:first-of-type').css('opacity', '1')
          stickyHeader.css('opacity', '1')

          stickyHeaderActive = true;

          return
        }
      }
    }

    var resizeTable = function() {
      if(_sticky_header) {
        _table_offset_left = _table_container_jq.offset()['left']
      }
      createTable();
    }

    return {
      init: function (id) {

        var that = this
        document.addEventListener('DOMContentLoaded', function() {
          ready = true
          that.init(id)
        })

        if(!ready)
          return

        _id = id;
        _container_el = $('#'+_id)[0];

        // if we don't find the table return early
        if(typeof _container_el === 'undefined')
          return

        _container_jq = $(_container_el);

        _subheadings = $(_container_el).find('tbody td .subheading').length > 0 ? true : false

        var table = _container_jq.find('table')[0];
        _container_el.removeChild(table);

        _table_container_el = document.createElement('div');
        _table_container_el.className = 'x131001_table_container';
        _container_el.appendChild(_table_container_el);
        _table_container_el = _container_jq.find('div.x131001_table_container')[0];
        _table_container_jq = $(_table_container_el);
        _table_container_el.appendChild(table);
        _table_container_jq.find('th').each(function() {
            var column_label = $(this).text();
            var has_sort = false;
            var is_bar = false;
            var is_asc = false;
            var is_tick = false;
            var is_rcmd = false;
            var is_hidden = false;
            var has_total = false;
            if (column_label.match(/\[.*sort.*\]/))
                has_sort = true;
            if (column_label.match(/\[.*width=([a-z\.]+).*\]/) !== null) {
                _width_columns[column_label.replace(/\[.*\]/, '')] = column_label.match(/\[.*width=([a-z\.]+).*\]/)[1]
                _width_enabled = true;
            }
            if (column_label.match(/\[.*asc.*\]/))
                is_asc = true;
            if (column_label.match(/\[.*bar.*\]/))
                is_bar = true;
            if (column_label.match(/\[.*tick.*\]/))
                is_tick = true;
            if (column_label.match(/\[.*rcmd.*\]/))
                is_rcmd = true;
            if (column_label.match(/\[.*hidden.*\]/))
                is_hidden = true;
            if (column_label.match(/\[.*totals.*\]/))
                has_total = true;
            column_label = column_label.replace(/\[.*\]/, '');
            if (is_hidden)
                column_label += '__hidden'; // allows [sort;hidden] labels to be the same as the non-hidden
            _column_labels.push(column_label);
            if (is_bar)
                _bar_columns[column_label] = true;
            if (is_tick)
                _tick_columns[column_label] = true;
            if (is_rcmd)
                _rcmd_columns[column_label] = true;
            if (is_hidden)
                _hidden_columns[column_label] = true;
            if (has_total)
                _has_totals = true;
            if (has_sort)
              _sort_columns[column_label] = 'sort'

        });
        // _column_values
        var trs_jq = _has_totals
            ? _table_container_jq.find('tr').slice(1, -1)
            : _table_container_jq.find('tr').slice(1);

        trs_jq.each(function() {
            var row_values = {};
            _values.push(row_values);
            $(this).find('td').each(function(i) {
                var column_label = _column_labels[i];
                row_values[column_label] = $(this).html();
            });
        });
        // _totals
        if (_has_totals) {
            _table_container_jq.find('tr').slice(-1).each(function() {
                $(this).find('td').each(function(i) {
                    var column_label = _column_labels[i];
                    _totals[column_label] = $(this).html();
                });
            });
        }
        // _sort_column_functions
        for (var i = 0; i < _column_labels.length; i++) {
            var column_label = _column_labels[i];
            var column_values = getUniqueColumnValues(column_label);
            var sortFunction = getSortFunction(column_values);
            _sort_column_functions[column_label] = sortFunction;
        }
        modifyTableContentsAndStructure();
        createTable();
        setContextualColours();
        window.addEventListener('resize', window.debounce(resizeTable, 500))

      }
    }
};
