$(() => {
  $('.attachment_file_field').on('change', function () {
    const maxFileNamesToDisplay = 3;
    // Building the content of the js-contact-file-label
    // Note that this.files is a FileList and not a regular array of files
    const fileNames = [...this.files].map((file) => file.name);
    $('.js-contact-file-label').text(fileNames.length <= maxFileNamesToDisplay ? fileNames.join(', ') : `${fileNames.length} files selected`);
  });

  $('.faux-select-wrap .faux-select').on('click', function () {
    $(this).parent().addClass('faux-select-wrap-active');
  });

  $('.faux-select-wrap .faux-select').on('blur', function () {
    $(this).parent().removeClass('faux-select-wrap-active');
  });

  $('.faux-select-options__option').on('mousedown', function () {
    const value = $(this).text();

    $(this).siblings().removeClass('faux-select-options__option-active');
    $(this).addClass('faux-select-options__option-active');
    $(this).parent().parent().find('.faux-select span')
      .removeClass('no-selection');
    $(this).parent().parent().find('.faux-select span')
      .text(value);
    $(this).parent().parent().find('input')
      .val(value);

    $(this).parent().parent().find(`select option[value='${value}']`)
      .prop('selected', true);
    $(this).parent().parent().removeClass('faux-select-wrap-active');

    if ($(this).hasClass('advice-options')) {
      const otherDesiredOutcome = $(this).closest('.rd-form-row').find('.other-outcome');
      if (value === 'Other') {
        otherDesiredOutcome.removeClass('dn');
      } else {
        otherDesiredOutcome.addClass('dn');
        otherDesiredOutcome.val('');
      }
    }
  });

  $('.master-form .faux-select-options__option').on('mousedown', function () {
    const data = $(this).data('form');
    $('.contact-form__master').hide();
    $(`.${data}`).show();
    $(`.${data}`).removeClass('form-lock');
  });
});
