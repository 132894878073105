/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(function() {
  if ($('[data-service-filter-results]').length <= 0) { return; }

  let data = $('[data-service-filter-results]').data('service-filter-results');
  if (Array.isArray(data[0])) {
    data = data[0];
  }

  const setResult = filterParams => {
    let filter, filter_element;
    let resultKey = '';
    if (!filterParams) {
      const url = new URL(document.location);
      for (filter of $('[data-filter]')) {
        filter_element = $(filter);
        filter_element.parent().removeClass("component-services-summary-select-hidden");
        url.searchParams.set(filter_element[0].dataset.filter, filter_element.val());
        resultKey += filter_element.val();
      }
      document.cookie = "filter_params=" + url.search.slice(1) + "; path=/;";
    } else {
      const searchParams = new URLSearchParams(decodeURIComponent(filterParams));
      for (filter of $('[data-filter]')) {
        filter_element = $(filter);
        filter_element.parent().removeClass("component-services-summary-select-hidden");
        const filter_value = searchParams.get(filter_element.attr('data-filter'));
        if (filter_value !== null) {
          filter_element.siblings('[class = "selected"]').text(filter_value);
          filter_element.val(filter_value);
          resultKey += filter_value;
        } else {
          resultKey += filter_element.val();
        }
      }
    }


    const filtered = data.length > 1 ? data.filter(x => x.hasOwnProperty(resultKey)) : data

    const paymentPeriod = $('.component-services-summary-premium-total').data('service-payment-period');

    if (filtered.length > 0) {
      let price = filtered[0][resultKey];

      if (typeof price === 'undefined') {
        price = '$ -';
      }

      if (!price.toString().replace(/\s/g, '').length) {
        price = '$ -';
      }

      if (!isNaN(parseInt(price))) {
        price = '$' + parseFloat(filtered[0][resultKey]).toFixed(2) + ' ' + paymentPeriod;
      }

      return $('.component-services-summary-premium-total > span').text(price);

    } else {
      return $('.component-services-summary-premium-total > span').text('$ -');
    }
  };

  $('[data-filter]').change(e => setResult());

  const filterParams = $.getCookie('filter_params');
  return setResult(filterParams);
});
