const foundation = {}
foundation.foundation = require('./foundation.js')
foundation.alerts = require('./foundation.alerts.js')
foundation.clearing = require('./foundation.clearing.js')
foundation.cookie = require('./foundation.cookie.js')
foundation.dropdown = require('./foundation.dropdown.js')
foundation.forms = require('./foundation.forms.js')
foundation.joyride = require('./foundation.joyride.js')
foundation.magellan = require('./foundation.magellan.js')
foundation.orbit = require('./foundation.orbit.js')
foundation.reveal = require('./foundation.reveal.js')
foundation.section = require('./foundation.section.js')
foundation.tooltips = require('./foundation.tooltips.js')
foundation.topbar = require('./foundation.topbar.js')
foundation.interchange = require('./foundation.interchange.js')
foundation.placeholder = require('./foundation.placeholder.js')
foundation.abide = require('./foundation.abide.js')

// run all imported foundation js
Object.keys(foundation).map(k => {
	foundation[k]()
})

// foundation.alerts
// foundation.clearing
// foundation.cookie
// foundation.dropdown
// foundation.forms
// foundation.joyride
// foundation.magellan
// foundation.orbit
// foundation.reveal
// foundation.section
// foundation.tooltips
// foundation.topbar
// foundation.interchange
// foundation.placeholder
// foundation.abide
