/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
//
// Owl carousel, docs available through https://github.com/OwlFonk/OwlCarousel
//

$.fn.inGroupsOf = function(inGroup) {
  let group;
  let offset = 0;
  const groups = [];
  while( (group = $(this).slice(offset, (inGroup + offset)) ).length > 0) {
    groups.push( group );
    offset += inGroup;
  }
  return groups;
};

$(function() {
  const $carousel = $('.carousel-compare');
  const $carouselOwl = $carousel.find('.owl');

  if ($carouselOwl.length > 0) {

    const setTotal = function() {
      const total = $carousel.find(".carousel-item").length;
      return $carousel.find(".pagination .total").html(total);
    };

    const maxHeightsIden = function(selector, $el) {
      if (selector === '.property-group') {
        return [selector, ' ', $el.data('property-group-row')].join('');
      } else if (selector === '.property-row') {
        return [selector, ' ', $el.data('property-field-row')].join('');
      } else {
        return selector;
      }
    };

    const equaliseHeights = function(self) {
      let $el, carouselItem, el, els, iden, selector;
      const selectors = [
        '.product-image',
        '.brand',
        '.model',
        '.row-summary-wrapper',
        '.row-secondary-criteria',
        '.row-pros',
        '.row-cons',
        '.row-features-wrapper',
        '.features',
        '.property-group',
        '.property-row'
      ];
      selectors.reverse();
      const maxHeights = {};
      const carouselItems = $('.owl-item.active').get();
      for (carouselItem of carouselItems) {
        for (selector of selectors) {
          els = $(carouselItem).find(selector).css('min-height', '').get();
          els.reverse();
          for (el of els) {
            $el = $(el);
            const height = $el.innerHeight();
            iden = maxHeightsIden(selector, $el);
            if (!maxHeights.hasOwnProperty(iden) || (height > maxHeights[iden])) {
              maxHeights[iden] = height;
            }
          }
        }
      }
      for (carouselItem of carouselItems) {
        for (selector of selectors) {
          els = $(carouselItem).find(selector).get();
          els.reverse();
          for (el of els) {
            $el = $(el);
            iden = maxHeightsIden(selector, $el);
            let minHeight = maxHeights[iden];
            // Firefox box-sizing not calculating correct height. Add 10 to all top level containers.
            if (!$el.hasClass('property-row')) { minHeight += 10; }
            $el.css('min-height', minHeight+'px');
          }
        }
        $(carouselItem).find('.metadata').last().css('border', '0');
      }
      return self.autoHeight();
    };

    $carouselOwl.owlCarousel({
      pagination: true,
      mouseDrag: false,
      touchDrag: true,
      addClassActive: true,

      afterInit() {
        return this.$elem.find('.owl-pagination').insertAfter(this.$elem.find('.owl-prev'));
      },

      // After init, move and update events
      afterAction() {
        $carousel.find('.carousel-wrapper').css({visibility:'visible'});
        setTotal();
        equaliseHeights(this);
        const self = this;
        return $carousel.find('img').one('load', () => equaliseHeights(self));
      },

      items: 3,
      itemsDesktop: [breakpoints.medium, 2],
      itemsDesktopSmall: [breakpoints.small, 1],
      itemsTablet: false,
      itemsTabletSmall: false,
      itemsMobile: false,
      rewindNav: false,
      navigation: true,
      autoHeight : true,
      navigationText: ["&lsaquo;","&rsaquo;"]
    });

    const carousel_data = $carouselOwl.data('owlCarousel');

    $carousel.find(".prev").on("click", carousel_data.prev);

    $carousel.find(".next").on("click", carousel_data.next);


    $(document).on("click", ".carousel-compare .icon-close", function(e) {
      e.preventDefault();
      const carousel_item = $(this).closest(".owl-item");
      const y_offset = window.pageYOffset;
      carousel_data.removeItem(carousel_item.index());
      window.scrollTo(0, y_offset);
      setTotal();
      return $(".owl-controls").insertAfter($carousel.find(".pagination"));
    });

    $(document).on('beforeConsumerPrint afterConsumerPrint', () => equaliseHeights(carousel_data));

    setTotal();
    return $(".owl-controls").insertAfter($carousel.find(".pagination"));
  }
});

window.alignCompareRowHeights = window.debounce(
    function() {
      const rows = $('.property-content:not(.fixed-header .property-content):not(.image_url)');
      let i = 0;
      while (i < rows.length) {
        let maxHeight = 0;
        const cells = rows[i].querySelectorAll('.cell');
        let j = 0;
        while (j < cells.length) {
          const cellHeight = cells[j].offsetHeight;
          if (maxHeight < cellHeight) {
            maxHeight = cellHeight;
          }
          j++;
        }
        rows[i].style.height = maxHeight + 'px';
        let k = 0;
        while (k < cells.length) {
          cells[k].style.height = maxHeight + 'px';
          k++;
        }
        i++;
      }
    },
    10
);

$(document).on('DOMContentLoaded', () => $('.comparable--slider').css('display', 'block'));


