/*jslint unparam: true, browser: true, indent: 2 */

module.exports = function init() { (function ($, window, document, undefined) {
    'use strict';

    Foundation.libs.dropdown = {
      name : 'dropdown',

      version : '4.3.2',

      settings : {
        activeClass: 'open',
        is_hover: false,
        opened: function(){},
        closed: function(){}
      },

      init : function (scope, method, options) {
        this.scope = scope || this.scope;
        Foundation.inherit(this, 'throttle scrollLeft data_options');

        if (typeof method === 'object') {
          $.extend(true, this.settings, method);
        }

        if (typeof method !== 'string') {

          if (!this.settings.init) {
            this.events();
          }

          return this.settings.init;
        } else {
          return this[method].call(this, options);
        }
      },

      events : function () {
        var self = this;

        $(this.scope)
          .on('click.fndtn.dropdown', '[data-dropdown]', function (e) {
            var settings = $.extend({}, self.settings, self.data_options($(this)));
            e.preventDefault();

            if (!settings.is_hover) self.toggle($(this));
          })
          .on('mouseenter', '[data-dropdown]', function (e) {
            var settings = $.extend({}, self.settings, self.data_options($(this)));
            if (settings.is_hover) self.toggle($(this));
          })
          .on('mouseleave', '[data-dropdown-content]', function (e) {
            var target = $('[data-dropdown="' + $(this).attr('id') + '"]'),
                settings = $.extend({}, self.settings, self.data_options(target));
            if (settings.is_hover) self.close.call(self, $(this));
          })
          .on('opened.fndtn.dropdown', '[data-dropdown-content]', this.settings.opened)
          .on('closed.fndtn.dropdown', '[data-dropdown-content]', this.settings.closed);

        $(document).on('click.fndtn.dropdown', function (e) {
          var parent = $(e.target).closest('[data-dropdown-content]');

          if ($(e.target).data('dropdown') || $(e.target).parent().data('dropdown')) {
            return;
          }
          if (!($(e.target).data('revealId')) &&
            (parent.length > 0 && ($(e.target).is('[data-dropdown-content]') ||
              $.contains(parent.first()[0], e.target)))) {
            e.stopPropagation();
            return;
          }

          self.close.call(self, $('[data-dropdown-content]'));
        });

        $(window).on('resize.fndtn.dropdown', self.throttle(function () {
          self.resize.call(self);
        }, 50)).trigger('resize');

        this.settings.init = true;
      },

      close: function (dropdown) {
        var self = this;
        dropdown.each(function () {
          if ($(this).hasClass(self.settings.activeClass)) {
            $(this)
              .css(Foundation.rtl ? 'right':'left', '-99999px')
              .removeClass(self.settings.activeClass);
            $(this).trigger('closed');
          }
        });
      },

      open: function (dropdown, target) {
          this
            .css(dropdown
              .addClass(this.settings.activeClass), target);
          dropdown.trigger('opened');
      },

      toggle : function (target) {
        var dropdown = $('#' + target.data('dropdown'));
        if (dropdown.length === 0) {
          // No dropdown found, not continuing
          return;
        }

        this.close.call(this, $('[data-dropdown-content]').not(dropdown));

        if (dropdown.hasClass(this.settings.activeClass)) {
          this.close.call(this, dropdown);
        } else {
          this.close.call(this, $('[data-dropdown-content]'))
          this.open.call(this, dropdown, target);
        }
      },

      resize : function () {
        var dropdown = $('[data-dropdown-content].open'),
            target = $("[data-dropdown='" + dropdown.attr('id') + "']");

        if (dropdown.length && target.length) {
          this.css(dropdown, target);
        }
      },

      css : function (dropdown, target) {
        var offset_parent = dropdown.offsetParent();
        // if (offset_parent.length > 0 && /body/i.test(dropdown.offsetParent()[0].nodeName)) {
          var position = target.offset();
          position.top -= offset_parent.offset().top;
          position.left -= offset_parent.offset().left;
        // } else {
        //   var position = target.position();
        // }

        if (this.small()) {
          dropdown.css({
            position : 'absolute',
            width: '95%',
            'max-width': 'none',
            top: position.top + this.outerHeight(target)
          });
          dropdown.css(Foundation.rtl ? 'right':'left', '2.5%');
        } else {
          if (!Foundation.rtl && $(window).width() > this.outerWidth(dropdown) + target.offset().left && !this.data_options(target).align_right) {
            var left = position.left;
            if (dropdown.hasClass('right')) {
              dropdown.removeClass('right');
            }
          } else {
            if (!dropdown.hasClass('right')) {
              dropdown.addClass('right');
            }
            var left = position.left - (this.outerWidth(dropdown) - this.outerWidth(target));
          }

          dropdown.attr('style', '').css({
            position : 'absolute',
            top: position.top + this.outerHeight(target),
            left: left
          });
        }

        return dropdown;
      },

      small : function () {
        return $(window).width() < 768 || $('html').hasClass('lt-ie9');
      },

      off: function () {
        $(this.scope).off('.fndtn.dropdown');
        $('html, body').off('.fndtn.dropdown');
        $(window).off('.fndtn.dropdown');
        $('[data-dropdown-content]').off('.fndtn.dropdown');
        this.settings.init = false;
      },

      reflow : function () {}
    };
  }(Foundation.zj, this, this.document));
}