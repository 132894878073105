/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

window.beforePrint = function() {
  window.isPrintView = true;
  return $(document).trigger('beforeConsumerPrint');
};

window.afterPrint = function() {
  window.isPrintView = false;
  return $(document).trigger('afterConsumerPrint');
};

window.onbeforeprint = beforePrint;
window.onafterprint = afterPrint;

if (window.matchMedia) {
  window.matchMedia('print').addListener(function(mql) {
    if (mql.matches) {
      return beforePrint();
    } else {
      return afterPrint();
    }
  });
}