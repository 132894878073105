/*jslint unparam: true, browser: true, indent: 2 */

module.exports = function init() { (function ($, window, document, undefined) {
    'use strict';

    Foundation.libs.abide = {
      name : 'abide',

      version : '4.3.3',

      settings : {
        live_validate : true,
        focus_on_invalid : true,
        timeout : 1000,
        patterns : {
          alpha: /[a-zA-Z]+/,
          alpha_numeric : /[a-zA-Z0-9]+/,
          integer: /-?\d+/,
          number: /-?(?:\d+|\d{1,3}(?:,\d{3})+)?(?:\.\d+)?/,

          // generic password: upper-case, lower-case, number/special character, and min 8 characters
          password : /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,

          // amex, visa, diners
          card : /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/,
          cvv : /^([0-9]){3,4}$/,

          // http://www.whatwg.org/specs/web-apps/current-work/multipage/states-of-the-type-attribute.html#valid-e-mail-address
          email : /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,

          url: /(https?|ftp|file|ssh):\/\/(((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?/,
          // abc.de
          domain: /^([a-zA-Z0-9]([a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}$/,

          datetime: /([0-2][0-9]{3})\-([0-1][0-9])\-([0-3][0-9])T([0-5][0-9])\:([0-5][0-9])\:([0-5][0-9])(Z|([\-\+]([0-1][0-9])\:00))/,
          // YYYY-MM-DD
          date: /(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))/,
          // HH:MM:SS
          time : /(0[0-9]|1[0-9]|2[0-3])(:[0-5][0-9]){2}/,
          dateISO: /\d{4}[\/\-]\d{1,2}[\/\-]\d{1,2}/,
          // MM/DD/YYYY
          month_day_year : /(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])[- \/.](19|20)\d\d/,

          // #FFF or #FFFFFF
          color: /^#?([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/
        }
      },

      timer : null,

      init : function (scope, method, options) {
        if (typeof method === 'object') {
          $.extend(true, this.settings, method);
        }

        if (typeof method !== 'string') {
          if (!this.settings.init) { this.events(); }

        } else {
          return this[method].call(this, options);
        }
      },

      events : function () {
        var self = this,
            forms = $('form[data-abide]', this.scope).attr('novalidate', 'novalidate');

        forms
          .on('submit validate', function (e) {
            var is_ajax = /ajax/i.test($(this).attr('data-abide'));
            return self.validate($(this).find('input, textarea, select').get(), e, is_ajax);
          });

        this.settings.init = true;

        if (!this.settings.live_validate) return;

        forms
          .find('input, textarea, select')
          .on('blur change', function (e) {
            self.validate([this], e);
          })
          .on('keydown', function (e) {
            clearTimeout(self.timer);
            self.timer = setTimeout(function () {
              self.validate([this], e);
            }.bind(this), self.settings.timeout);
          });
      },

      validate : function (els, e, is_ajax) {
        var validations = this.parse_patterns(els),
            validation_count = validations.length,
            form = $(els[0]).closest('form'),
            submit_event = /submit/.test(e.type);

        for (var i=0; i < validation_count; i++) {
          if (!validations[i] && (submit_event || is_ajax)) {
            if (this.settings.focus_on_invalid) els[i].focus();
            form.trigger('invalid');
            $(els[i]).closest('form').attr('data-invalid', '');
            return false;
          }
        }

        if (submit_event || is_ajax) {
          form.trigger('valid');
        }

        form.removeAttr('data-invalid');

        if (is_ajax) return false;

        return true;
      },

      parse_patterns : function (els) {
        var count = els.length,
            el_patterns = [];

        for (var i = count - 1; i >= 0; i--) {
          el_patterns.push(this.pattern(els[i]));
        }

        return this.check_validation_and_apply_styles(el_patterns);
      },

      pattern : function (el) {
        var type = el.getAttribute('type'),
            required = typeof el.getAttribute('required') === 'string';

        if (this.settings.patterns.hasOwnProperty(type)) {
          return [el, this.settings.patterns[type], required];
        }

        var pattern = el.getAttribute('pattern') || '';

        if (this.settings.patterns.hasOwnProperty(pattern) && pattern.length > 0) {
          return [el, this.settings.patterns[pattern], required];
        } else if (pattern.length > 0) {
          return [el, new RegExp(pattern), required];
        }

        pattern = /.*/;

        return [el, pattern, required];
      },

      check_validation_and_apply_styles : function (el_patterns) {
        var count = el_patterns.length,
            validations = [];

        for (var i = count - 1; i >= 0; i--) {
          var el = el_patterns[i][0],
              required = el_patterns[i][2],
              value = el.value,
              is_radio = el.type === "radio",
              valid_length = (required) ? (el.value.length > 0) : true;

          if (is_radio && required) {
            validations.push(this.valid_radio(el, required));
          } else {
            if (el_patterns[i][1].test(value) && valid_length ||
              !required && el.value.length < 1) {
              $(el).removeAttr('data-invalid').parent().removeClass('error');
              validations.push(true);
            } else {
              $(el).attr('data-invalid', '').parent().addClass('error');
              validations.push(false);
            }
          }
        }

        return validations;
      },

      valid_radio : function (el, required) {
        var name = el.getAttribute('name'),
            group = document.getElementsByName(name),
            count = group.length,
            valid = false;

        for (var i=0; i < count; i++) {
          if (group[i].checked) valid = true;
        }

        for (var i=0; i < count; i++) {
          if (valid) {
            $(group[i]).removeAttr('data-invalid').parent().removeClass('error');
          } else {
            $(group[i]).attr('data-invalid', '').parent().addClass('error');
          }
        }

        return valid;
      }
    };
  }(Foundation.zj, this, this.document));
}