/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(function() {
  return $('.ajax-sign-up').bind("ajax:success", function(e, data, status, xhr) {

    if (xhr.statusText === 'Created') {
      return $.showSuccess();
    } else {
      return alert("Please reload the page and try again");
    }

  });
});