/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(function() {
  var quoteSingle = function quoteSingle(s) {
    return s.replace(/'/g, "\\'");
  };

  $('#share a').click(function() {

    var page_title = $('title').text();
    var jq = $(this);
    var medium = 'Other';
    if (jq.find('i.facebook').size() > 0) {
      medium = 'Facebook';
    } else if (jq.find('i.twitter').size() > 0) {
      medium = 'Twitter';
    } else if (jq.find('i.pinterest').size() > 0) {
      medium = 'Pinterest';
    } else if (jq.find('i.email').size() > 0) {
      medium = 'Email';
    }
    if (typeof ga === 'undefined' || ga === null) { return; }
    ga('send', 'event', 'Content', 'Shared', quoteSingle(page_title));
    return ga('send', 'event', 'Content', 'SharedWith', medium);
  });

  // Track external link clicks and internal PDFs
  return $('body').on('click', 'a[href^="http"], a[href$=".pdf"]', function(e) {
    var hitCallbackFunction;
    var link = $(e.target).closest('a');

    var target = link.prop('target');
    var url = link.prop('href');

    if (typeof ga === 'undefined' || ga === null) { return; }

    //https://developers.google.com/analytics/devguides/collection/analyticsjs/sending-hits
    //see 'handle timeouts' section.
    var createFunctionWithTimeout = function createFunctionWithTimeout(callback, opt_timeout) {
      var called = false;

      var fn = function fn() {
        if (!called) {
          called = true;
          callback();
        }
      };

      setTimeout(fn, opt_timeout || 1000);
      return fn;
    };

    if (target === '_blank') {
      // for links opening in a new window/tab,
      // we don't need a callback from google as this script
      // will still running in this page. So the event should
      // happen according to the browser
      hitCallbackFunction = null;
    } else {
      // for links opening in the same tab
      // we need to use the google callback function, and prevent the
      // link to be opened before the ga function is called
      e.preventDefault();
      hitCallbackFunction = createFunctionWithTimeout(function() {
        document.location = url;
      });
    }

    return ga('send', 'event', 'Link', 'Clicked', url, {'hitCallback': hitCallbackFunction});
  });
});
