/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$.fn.disguise_select = function() {
  var update_value = function update_value() {
    var select = $(this);
    if (select.parent().hasClass('select')) {
      var option = select.find('option:selected');
      var value = select.siblings('.selected');
      value.text(option.text());
      return select.toggleClass('prompt', option.hasClass('prompt'));
    }
  };

  $(this).each(function() {
    var select = $(this);
    if (!select.parent().hasClass('select')) {
      select.wrap($('<div />', {"class":"select ".concat(select.attr('class'))}))
             .after($('<span />', {"class":'selected'}))
             .after($('<i />'));
    }
    return update_value.call(select);
  });

  $(this).closest('form').find('select').on('change', update_value)
    .on('focus', 'select', function() {
      $(this).closest('.select').addClass('focus');
      return $(this).click();
    });
  $(this).closest('form').on('blur change focusout', 'select', function() {
    return $(this).closest('.select').removeClass('focus');
  });
  return $(this);
};