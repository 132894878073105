// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//

import 'core-js/stable';
import 'regenerator-runtime/runtime';

// javascripts
import '../application/javascripts/index.js';

// stylesheets
import '../application/stylesheets/application.scss';

// eslint-disable-next-line no-unused-vars
const images = require.context('../application/images/', true);
