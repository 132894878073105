/* eslint-disable no-underscore-dangle */
// NOTE: Algolia, in all their wisdom, has seemingly removed all mention of their old
// methods and documentation, so finding code that relates to the script below is hard.
// Use this old readme of the library as a reference instead:
// https://github.com/algolia/autocomplete/blob/8736817914b0e78d6b67c39892582821bdbd15b8/README.md#options

const algolia = algoliasearch(
  window.consumerAlgoliaApplicationId,
  window.consumerAlgoliaFrontendApiKey
);

const index = algolia.initIndex(
  window.consumerAlgoliaIndexName
);

let searchQuery = '';
let lastStoredQuery; // Track the last query sent to GA

const searchIsNew = () => {
  const isNew = !lastStoredQuery || lastStoredQuery !== searchQuery;
  lastStoredQuery = searchQuery;
  return isNew;
};

// Debounce for a second before sending search updates. Only send new queries to GA.
const sendQueryToGA = () => {
  if (searchIsNew()) {
    window.dataLayer.push({
      event: 'search_query',
      search_term: searchQuery
    });
  }
};

const debouncedSendQueryToGA = window.debounce(sendQueryToGA, 1000);

$(() => {
  // Search "magnifying glass" submit button
  const searchSubmit = $('.js-search-submit');
  // Autocomplete search element
  const searchElement = $('.js-search-input');
  searchElement.autocomplete(
    {
      hint: false,
      minLength: 3,
      cssClasses: {
        root: 'autocomplete-wrapper',
        prefix: 'autocomplete'
      }
    },
    [{
      source: $.fn.autocomplete.sources.hits(index, { hitsPerPage: 5 }),
      displayKey: 'name',
      templates: {
        suggestion(suggestion) {
          let name = suggestion._highlightResult.title.value;

          if (suggestion.page_type === 'Product') {
            name = `${suggestion._highlightResult.brand.value} - ${name}`;
          }

          return `<span><a href=${suggestion.url}>${name}</a></span>`;
        },
        footer(input) {
          return `<a href=/search?query=${input.query}><div class="autocomplete-suggestion see-more"><span>More results</span></div></a>`;
        }
      }
    }]
  );

  searchElement.on('autocomplete:selected', (event, suggestion) => {
    // Stop tracking query updates now that we are leaving the page
    debouncedSendQueryToGA.cancel();
    // Make sure we get the last search term
    sendQueryToGA();

    // Handle empty suggestion when the footer "More" is clicked
    const seeMoreClick = event && !suggestion;
    if (seeMoreClick) {
      window.dataLayer.push({
        event: 'search_submit',
        search_term: searchQuery
      });
      return;
    }

    let suggestionTitle = suggestion.title;
    if (suggestion.page_type === 'Product') {
      suggestionTitle = `${suggestion.brand} - ${suggestionTitle}`;
    }

    searchElement.autocomplete('val', suggestionTitle);
    searchElement.autocomplete('close');
    event.target.form.submit();

    // Send data on the selected item to GA
    window.dataLayer.push({
      event: 'search_result_click',
      search_term: searchQuery,
      link_url: suggestion.url
    });
  });

  searchElement.on('autocomplete:updated', (event) => {
    searchQuery = event.currentTarget.value;
    debouncedSendQueryToGA();
  });

  // Catch submit events which are not in autocomplete
  searchSubmit.on('click', () => {
    debouncedSendQueryToGA.cancel();
    // Make sure we get the last search term
    sendQueryToGA();

    window.dataLayer.push({
      event: 'search_submit',
      search_term: searchQuery
    });
  });
});
