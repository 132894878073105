/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import tippy from 'tippy.js';

$(() => {
  $.bindTooltips = function () {
    const tooltipElements = Array.prototype.slice.call(document.querySelectorAll('.has-tip'));
    if (tooltipElements.length === 0) {
      return;
    }
    return tooltipElements.map((el) => {
      // generate html from title attr
      let value = '';

      if (el.attributes.title) {
        ({
          value
        } = el.attributes.title);
      } else {
        ({
          value
        } = el.attributes['data-original-title']);
      }
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = value;
      // call tippy
      return tippy(el, {
        arrow: false,
        interactive: true,
        interactiveBorder: 0,
        allowHTML: true,
        theme: 'light',
        content: tempDiv.innerHTML,
        zIndex: 9999,
        popperOptions: {
          modifiers: {
            flip: {
              enabled: false
            }
          }
        },
        onShown: function() {
          $('.tippy-content a').click(function(event){
            event.stopPropagation();
            event.preventDefault();
            const target = event.target;
            window.open(target.href, '_blank');
          });
        },
        onUntrigger: function() {
          $('.tippy-content a').unbind('click')
        }
      });
    });
  };

  return $.bindTooltips();
});
