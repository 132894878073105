/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(function() {
  if ($('#load-more').length > 0) {
    return $('#load-more').on('click', function() {
      const url = $('.pagination [rel="next"]').attr("href") + '&' + $('form#filter').serialize();
      return $.ajax({
        url,
        dataType: "script",
        success() {
          $.count_pagination();
          $.bindTooltips();
          return $('#load-more').text('Load more');
        }
        ,
        beforeSend() {
          return $('#load-more').text('Submitting...').addClass('disabled');
        }
      });
    });
  }
});
