$('html').on('session:pinged', () => {
  if (!($('#reviews').length > 0)) { return; }

  const bindShowMore = () => {
    $('[data-more-reviews]').not('.click-bound').on('click', () => {
      $('[data-overflow-reviews]').show();
      $('[data-more-reviews]').addClass('disabled');
    });
    $('[data-more-reviews]').addClass('click-bound');
  };

  if (!($('[data-review-modal]').length > 0)) { return; }

  const bindCreateReview = () => {
    $('[data-create-review]').not('.click-bound').on('click', () => {
      $('[data-create-review]').hide();
      $('[data-review-modal]').show();

      $('form#new_review')[0].reset();
      $('input[name="review[rating]"]:checked').prop('checked', false);
      $('input[name="review[rating]"]').trigger('change');
      $('[name="review[period_used]"]').trigger('change');

      const reviewForm = $('#new_review');
      $('#new_review').find('input[name=_method]').remove();
      reviewForm[0].action = $('[data-create-path]').data('create-path');
      $('input#review_base').prop('value', 'Submit review');
    });

    $('[data-create-review]').addClass('click-bound');

    $('[data-review-modal-close]').not('.click-bound').on('click', () => {
      $('[data-review-modal]').hide();
      $('[data-create-review]').show();
    });

    $('[data-review-modal-close]').addClass('click-bound');

    $('form#new_review input[name="review[rating]"]').not('.bound').on('change', function () {
      if ($(this).is(':checked')) {
        const kiwiLabel = $(this).closest('label');
        kiwiLabel.nextAll('.radio').find('input[name="review[rating]"]').removeClass('checked');
        kiwiLabel.nextAll('.radio').find('input[name="review[rating]"]').parent().removeClass('checked');
        $(this).addClass('checked');

        $(this).parent().addClass('checked');
        kiwiLabel.prevAll('.radio').find('input[name="review[rating]"]').addClass('checked');
        kiwiLabel.prevAll('.radio').find('input[name="review[rating]"]').parent().addClass('checked');
      } else {
        $(this).removeClass('checked');
        $(this).parent().removeClass('checked');
        $(this).find('.checked').removeClass('checked');
      }
    });

    $('form#new_review input[name="review[rating]"]:checked').trigger('change');

    $('form#new_review input[name="review[rating]"]').addClass('bound');
  };

  const bindEditReview = (elm) => $(elm).find('[data-update-path]').on('click', () => {
    $('[data-review-modal]').show();
    $('[name="review[title]"]').val(
      $(elm).closest('[data-review-title]').data('review-title')
    );
    $('[name="review[positive_points]"]').val(
      $(elm).closest('[data-review-positive]').data('review-positive')
    );
    $('[name="review[negative_points]"]').val(
      $(elm).closest('[data-review-negative]').data('review-negative')
    );
    const rating = $(elm).closest('[data-review-rating]').data('review-rating');
    $(`input#review_rating_${rating}`).prop('checked', true);
    $('input[name="review[rating]"]:checked').trigger('change');

    const recommended = $(elm).closest('[data-review-recommended]').data('review-recommended');
    $(`input[value=${recommended}]`).prop('checked', true);

    const period = $(elm).closest('[data-review-period]').data('review-period');
    $('[name="review[period_used]"]').val(period);
    $('[name="review[period_used]"]').trigger('change');

    const reviewForm = $('#new_review');
    const crudMethod = document.createElement('input');
    crudMethod.type = 'hidden';
    crudMethod.name = '_method';
    crudMethod.value = 'put';

    reviewForm[0].action = $('[data-update-path]').data('update-path');
    reviewForm.append(crudMethod);

    $('input#review_base').prop('value', 'Update review');
  });

  const bindPersonalControls = () => {
    if (!($('[data-current-member]').length > 0)) { return; }
    if (!($('[data-author]').length > 0)) { return; }
    const current = $('[data-current-member]').data('current-member');
    $('[data-author]').not('.control-bound').each((i, elm) => {
      $(elm).addClass('control-bound');
      if ($(elm).data('author') === current) {
        $(elm).find('[data-review-controls]').show();

        bindEditReview(elm);
      }
    });
  };

  bindShowMore();
  bindCreateReview();
  bindPersonalControls();

  $('html').on('consumer:reviews:refreshed', () => {
    bindShowMore();
    bindCreateReview();
    bindPersonalControls();
  });
});
