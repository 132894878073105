$(() => {
  const $giftForm = $('.js-gift-form');
  if (!$giftForm.length) { return; }

  const addRecipientValidations = (e, index) => {
    // Recipient first name
    const recipientFirstName = `[name="recipients[${index}][receiver_first_name]"]`;
    $(recipientFirstName).rules('add', {
      required: true
    });

    // Recipient last name
    const recipientLastName = `[name="recipients[${index}][receiver_last_name]"]`;
    $(recipientLastName).rules('add', {
      required: true
    });

    // Recipient email
    const recipientEmail = `[name="recipients[${index}][receiver_email]"]`;
    $(recipientEmail).rules('add', {
      required() { return $(`#recipients_${index}_emailed`).val() === 'true'; },
      email: true,
      remote: {
        data: {
          email() { return $(recipientEmail).val(); }
        },
        type: 'GET',
        url: $('[data-validate-email-url]').data('validate-email-url'),
      },
      doesNotMatchGifterEmail: true,
      doesNotMatchRecipientEmails: true
    });

    // Recipient gift date
    const recipientGiftDate = `[name="recipients[${index}][gift_date]"]`;
    $(recipientGiftDate).rules('add', {
      required() { return $(`#recipients_${index}_emailed`).val() === 'true'; }
    });
  };

  const removeRecipientValidations = (e, index) => {
    $(`[name^="recipients[${index}]"]`).each((i, field) => {
      $(field).rules('remove');
    });
  };

  const initValidator = () => {
    // Set validation messages for each recipient
    const recipientMessages = {};
    for (let index = 0; index < 5; index++) {
      const giftDateKey = `recipients[${index}][gift_date]`;
      const receiverEmailKey = `recipients[${index}][receiver_email]`;

      recipientMessages[giftDateKey] = 'Required to send email to recipient.';
      recipientMessages[receiverEmailKey] = {
        remote: 'Please enter a valid email.'
      };
    }

    // Matching email validation methods:
    // Validate recipient email does not match gifted
    $.validator.addMethod('doesNotMatchGifterEmail', function (value, element) {
      if (this.optional(element)) { return true; }
      const gifterEmail = $('.js-gift-email').val();
      return gifterEmail !== value;
    }, 'You cannot send yourself a gift');

    // Validate recipient email does not match other recipients
    $.validator.addMethod('doesNotMatchRecipientEmails', function (value, element) {
      if (this.optional(element)) { return true; }
      if (!value) return true;
      const $recipientEmails = $('.js-gift-recipient-email:visible')
        .filter((i, field) => field.value.toLowerCase() === value.toLowerCase());
      return $recipientEmails.length === 1;
    }, 'You cannot send multiple gifts to the same email address');

    $giftForm.validate({
      onkeyup: false,
      rules: {
        'gift[gifter_email]': {
          required: true,
          email: true,
          remote: {
            data: {
              email() { return $('#gift_gifter_email').val(); }
            },
            type: 'get',
            url: $('[data-validate-email-url]').data('validate-email-url'),
          }
        },
        'gift[gifter_first_name]': {
          required: true
        },
        'gift[gifter_last_name]': {
          required: true
        },
        'gift[promo_code_id]': {
          required() {
            return ($('.js-promo-input').val().length > 0) || ($('.js-promo-code-id').val().length > 0);
          },
          remote: {
            data: {
              promo_code() { return $('.js-promo-input').val(); },
              membership_product_id() { return $('.js-promo-product-id').val(); },
              has_magazine() { return $('.js-receives-magazine').val(); },
              gift: true,
              quantity() { return $('.js-gift-recipient:visible').length; }
            },
            type: 'GET',
            url: $('[data-validate-promo-url]').data('validate-promo-url'),
            dataType: 'JSON'
          }
        },
      },
      messages: {
        ...recipientMessages,
        'gift[promo_code_id]': {
          required: 'Please apply or remove your promo code',
          remote: 'Please apply a valid code'
        },
        'gift[gifter_email]': {
          remote: 'Please enter a valid email'
        }
      },
      focusInvalid: true,
      errorClass: 'invalid',
      errorElement: 'div',
      ignore: 'input[type="text"]:hidden',
      showErrors(errorMap, errorList) {
        console.log(errorList.length);
        // eslint-disable-next-line prefer-arrow-callback
        errorList.forEach((el) => {
          // eslint-disable-next-line no-param-reassign
          el.message = `<div class='arrow'></div>${el.message}`;
        });
        return this.defaultShowErrors();
      },
      errorPlacement(error, element) {
        // hide server-side error if present
        element.next('.field-error').remove();
        // workarround to add styled arrow
        error.addClass('field-error');

        if (element[0].type === 'checkbox') {
          error.insertBefore(element.parent().parent());
        } else if (element[0].name === 'gift[promo_code_id]') {
          window.promoCodes.setInvalidPromoCode();
          error.addClass('js-promo-id-error w-100 mt3 tl');
          error.insertAfter($('.js-promo-message'));
        } else {
          error.insertAfter(element);
        }
      }
    });
  };

  // Validation set up
  initValidator();

  // Add validations for additional recipients
  $('html').on('gift.form.rules.add', addRecipientValidations);

  // Remove validations for removed recipients
  $('html').on('gift.form.rules.remove', removeRecipientValidations);

  // Add first set of recipient validations
  addRecipientValidations(null, 0);
});
