$(() => {
  const hasBtt = $('.js-back-to-top').length > 0;
  if (!hasBtt) { return; }

  const backToTopButton = $('.js-back-to-top');

  const header = $('.content-header');
  const footer = $('#page-footer');
  let headerBottom;
  let footerTop;

  let disableUpdates = false;

  const calculateHeaderBottom = () => {
    if (!$(window).width()) return;
    // Account for sticky nav (86px high) on desktop by checking the screen size
    // Bottom position adjusted by -1 to ensure it appearance at very top of body
    headerBottom = ($(window).width() >= 1040)
      ? header.position().top + header.outerHeight(true) - 86 - 1
      : header.position().top + header.outerHeight(true) - 1;
  };

  const calculateFooterTop = () => {
    if (!$(window).height()) return;
    // Get the top of the footer and adjust by the window height, because we use scrollTop to check
    // how far the user has scrolled later in the code
    footerTop = footer.offset().top - $(window).height();
  };

  const showBTTButton = (visible = true) => {
    if (disableUpdates) return;
    if (visible && backToTopButton.hasClass('dn')) backToTopButton.removeClass('dn');
    if (!visible && !backToTopButton.hasClass('dn')) backToTopButton.addClass('dn');
  };

  const scrollToTop = () => {
    $.scrollTo('#top', 0, 500);
    showBTTButton(false);
    disableUpdates = true;
    setTimeout(() => { disableUpdates = false; }, 500);
  };

  // The button only appears if the user is scrolled between the
  // bottom of the header and the top of the footer.
  const checkBTTButtonDisplay = () => {
    if (!headerBottom) calculateHeaderBottom();
    if (!footerTop) calculateFooterTop();
    const currScrollHeight = $(window).scrollTop();

    if (currScrollHeight >= headerBottom && currScrollHeight <= footerTop) showBTTButton(true);
    else showBTTButton(false);
  };

  backToTopButton.on('click', () => {
    scrollToTop();
  });

  $(window).on('scroll', () => {
    checkBTTButtonDisplay();
  });

  // On load or resize, force an update on the headerBottom
  $(window).on('load', () => {
    headerBottom = null;
    footerTop = null;
    checkBTTButtonDisplay();
  });

  $(window).on('resize', () => {
    headerBottom = null;
    footerTop = null;
    checkBTTButtonDisplay();
  });
});
