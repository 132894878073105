/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(function() {
  var resizeWindow = function resizeWindow() {
    if (typeof(Event) === 'function') {
      return window.dispatchEvent(new Event('resize'));
    } else {
      var evt = window.document.createEvent('UIEvents');
      evt.initUIEvent('resize', true, false, window, 0);
      return window.dispatchEvent(evt);
    }
  };

  $("[data-toggle-input]").on("change", function(e) {
    var target = $('[data-target="' + $(this).data('toggle-input') + '"]');
    if ($(this).find(":selected").text() === 'Other') {
      return target.show();
    } else {
      return target.hide();
    }
  });

  $('[data-toggle-input]').each(function() {
    // Show/hide toggled 'Other' text field if previously selected (e.g. user hit back button)
    return $(this).trigger('change');
  });

  return $("[data-show-more-button]").on("click", function(e) {
    var $btn = $(this);
    var $originalText = $btn.data('text');

    // Find the extended content area within the scope of the current module.
    var extendedContentName = $btn.data('show-more-button');
    var $reusable = $btn.parents('.js-reusable');
    var $extendedContent = $reusable.find("[data-target='".concat(extendedContentName, "']"));

    if ($extendedContent.hasClass('js-dn')) {
      $extendedContent.hide();
      $extendedContent.removeClass('js-dn');
    }
    $extendedContent.slideToggle( function() {
      if($extendedContent.is(':visible')) {
        return $btn.text('Less');
      } else {
        return $btn.text($originalText);
      }
    });

    return resizeWindow();
  });
});
