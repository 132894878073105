/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$.fn.selectAllOnFocus = function() {
  // This solution is from:
  // http://stackoverflow.com/questions/3380458/looking-for-a-better-workaround-to-chrome-select-on-focus-bug
  // It's simple, but may or may not work with keyboard-tab select
  // depending on the browser.
  return $(this).focus(function() {
    return $(this).select().mouseup(function(e) {
        e.preventDefault();
        return $(this).unbind("mouseup");
    });
  });
};


$(function() {
  return $('[data-select-all-on-focus]:input').selectAllOnFocus();
});