/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(function() {
  $('#products-directory .chevron').on('click', function() {
    return setTimeout((function() {
      if ($('#products-directory').find('.sidebar-inactive').length > 0) {
        $('.table-perc').addClass('table-perc-wide');
        $('.table-desc').addClass('table-desc-wide');
      } else {
        $('.table-perc').removeClass('table-perc-wide');
        $('.table-desc').removeClass('table-desc-wide');
      }
    }), 250);
  });



});
