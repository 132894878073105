$(() => {
  // Member account dropdown
  const $memberWrapper = $('.js-header-member-wrapper');
  const $memberToggle = $('.js-header-member-toggle');
  const $memberDropdown = $('.js-header-member-dropdown');

  const toggleDropdown = () => {
    $memberToggle.toggleClass('open');
    $memberDropdown.slideToggle(200);

    const expanded = $memberToggle.attr('aria-expanded') === 'true';
    $memberToggle.attr('aria-expanded', String(!expanded));
    $memberToggle.attr('aria-label', expanded ? 'Open account menu' : 'Close account menu');
    $memberDropdown.attr('aria-hidden', String(expanded));

    if (!expanded) {
      $memberWrapper.bind('clickoutside', () => {
        toggleDropdown();
        $memberWrapper.unbind('clickoutside');
      });
    }
  };

  $memberToggle.on('click', toggleDropdown);
});
