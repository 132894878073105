/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(function() {
  if (!($('#my-favourites').length > 0)) { return; }

  var bindFavouriteLists = function bindFavouriteLists() {
    return $('.fav-title').click(function() {
      if ($(this).parent().hasClass('fav-open')) {
        return $(this).parent().removeClass('fav-open');
      } else {
        return $(this).parent().addClass('fav-open');
      }
    });
  };

  var bindFavouriteEditShow = function bindFavouriteEditShow() {
    return $('.fav-title-edit').click(function(e) {
      e.stopPropagation();

      if ($('.fav-title-form').is(":visible")) {
        $('.fav-title-form').hide();
      }

      $("form#edit_bookmark_list_".concat($(this).data('favourite-list-id'))).show();
      var favouriteEditShowInput = $("form#edit_bookmark_list_".concat($(this).data('favourite-list-id'), " .fav-title-form-input"));
      var storeFavouriteEditShowInput = favouriteEditShowInput.val();
      favouriteEditShowInput.select();
      return favouriteEditShowInput.blur().focus().val(storeFavouriteEditShowInput);
    });
  };

  var bindFavouriteEdit = function bindFavouriteEdit() {
    return $('.fav-title-form').click(function(e) {
      return e.stopPropagation();
    });
  };

  var bindDefavourite = function bindDefavourite() {
    return $('.fav-entry-delete').click(function(e) {
      var defav;
      e.stopPropagation();
      e.preventDefault();
      return defav = $.ajax({type: 'DELETE', url: $(this).data('defavourite-path')});
    });
  };

  var bindDelete = function bindDelete() {
    return $('.fav-title-delete').click(function(e) {
      var defav;
      e.stopPropagation();
      return defav = $.ajax({type: 'DELETE', url: $(this).data('favourite-list-path')});
    });
  };

  var bindCreate = function bindCreate() {
    $('.fav-create').click(function(e) {
      e.stopPropagation();
      $('#my-favourites-lists').addClass('active__listing');
      if ($('#new_bookmark_list').is(":visible")) {
        $('#new_bookmark_list').hide();
        return $(this).focus();
      } else {
        $('#new_bookmark_list').show();
        return $('#bookmark_list_title').focus();
      }
    });

    $('#new_bookmark_list').click(function(e) {
      return e.stopPropagation();
    });

    return $(document).click(function(e) {
      $('#new_bookmark_list').hide();
      $('.fav-title-form:visible').submit();
      return $('.fav-title-form').hide();
    });
  };

  var bindSubmit = function bindSubmit() {
    $('#new_bookmark_list').submit(function(e) {
      e.preventDefault();
      return $(this).hide();
    });

    return $('.fav-title-form').on('submit', function() {
      return $(this).hide();
    });
  };

  var bindReady = function bindReady() {
    return $(document).ready(function(e) {
      return $('#my-favourites-lists').removeClass('loading');
    });
  };

  var bindLoad = function bindLoad() {
    $('#my-favourites-lists').removeClass('loading');
    return $('.fav-title').parent().removeClass('loading');
  };

  bindFavouriteLists();
  bindFavouriteEdit();
  bindFavouriteEditShow();
  bindDefavourite();
  bindCreate();
  bindDelete();
  bindSubmit();
  bindLoad();
  bindReady();

  return $('html').on('consumer:favourites:refreshed', function() {
    bindFavouriteLists();
    bindFavouriteEdit();
    bindFavouriteEditShow();
    bindDefavourite();
    bindCreate();
    bindDelete();
    bindSubmit();
    return bindLoad();
  });
});
