/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// this is so we can detect from CSS whether the soft keyboard is (maybe) visible.
// Make sure your css based on `.keyboard_visible` is ok with false positives.
//  - a mobile device might be hooked up to a bluetooth keyboard
//  - it might just be a very narrow desktop browser window
//  - also shows up for select for mobile phone select spinner

$.fn.watch_for_keyboard = function() {
  var textish_inputs_selector = 'select, input:not([type="submit"], [type="checkbox"], [type="radio"]), textarea';
  $(this).on('touchstart focus', textish_inputs_selector, function(e) {
    return $('body').addClass('keyboard_visible');
  });


  var remove_keyboard_visible_class = function remove_keyboard_visible_class() {
    if (!$(document.activeElement).is(textish_inputs_selector)) {
      return $('body').removeClass('keyboard_visible');
    }
  };

  return $(this).on('blur', textish_inputs_selector, function(e) {
    return (
      // the timeout is so that the keyboard has time to disappear
      // the number is arbritrary: a couple of trials and a couple of phones.
      setTimeout(remove_keyboard_visible_class, 300)
    );
  });
};

$.fn.selectTab = function() {
  var tab = $("[id='".concat($(this).attr('id'), "']")).closest('.title');
  var section = tab.closest('.section-container section');
  tab.addClass('active').siblings().removeClass('active');
  return section.addClass('active').siblings().removeClass('active');
};

$.fn.getTarget = function() { if ($(this).is('a[href^="#"]')) { return $($(this).attr('href')); } };
$.fn.offsetBottom = function() { return $(this).height() + $(this).offset().top; };

// from underscorejs
// A (possibly faster) way to get the current timestamp as an integer.
$.now = Date.now || function() {
  return new Date().getTime();
};

// from underscorejs
window.throttle = function(func, wait, options) {
  if (options == null) { options = {}; }
  if (options.leading == null) { options.leading = true; }
  if (options.trailing == null) { options.trailing = true; }
  var context = null;
  var args = null;
  var result = null;
  var timeout = null;
  var previous = 0;
  var later = function later() {
    var now = $.now();
    previous = options.leading === false ? 0 : $.now();
    timeout = null;
    result = func.apply(context, args);
    return context = (args = null);
  };

  var throttler = function throttler() {
    var now = $.now();
    if ((previous === 0) && !options.leading) {
      previous = now;
    }
    var remaining = wait - (now - previous);
    context = this;
    args = arguments;
    if ((remaining <= 0) || (remaining > wait)) {
      clearTimeout(timeout);
      timeout = null;
      previous = now;
      result = func.apply(context, args);
      context = (args = null);
    } else if (!timeout && options.trailing) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };

  return throttler;
};

// debounce from lodash
window.debounce = function(func, wait) {
  var lastArgs = undefined;
  var lastThis = undefined;
  var maxWait = undefined;
  var result = undefined;
  var timerId = undefined;
  var lastCallTime = undefined;
  var lastInvokeTime = 0;
  var leading = false;
  var maxing = false;
  var trailing = true;

  var invokeFunc = function invokeFunc(time) {
    var args = lastArgs;
    var thisArg = lastThis;
    lastArgs = (lastThis = undefined);
    lastInvokeTime = time;
    result = func.apply(thisArg, args);
    return result;
  };

  var leadingEdge = function leadingEdge(time) {
    // Reset any `maxWait` timer.
    lastInvokeTime = time;
    // Start the timer for the trailing edge.
    timerId = setTimeout(timerExpired, wait);
    // Invoke the leading edge.
    if (leading) { return invokeFunc(time); } else { return result; }
  };

  var remainingWait = function remainingWait(time) {
    var result;
    var timeSinceLastCall = time - lastCallTime;
    var timeSinceLastInvoke = time - lastInvokeTime;
    result = wait - timeSinceLastCall;
    if (maxing) { return nativeMin(result, maxWait - timeSinceLastInvoke); } else { return result; }
  };

  var shouldInvoke = function shouldInvoke(time) {
    var timeSinceLastCall = time - lastCallTime;
    var timeSinceLastInvoke = time - lastInvokeTime;
    // Either this is the first call, activity has stopped and we're at the
    // trailing edge, the system time has gone backwards and we're treating
    // it as the trailing edge, or we've hit the `maxWait` limit.
    return (lastCallTime === undefined) || (timeSinceLastCall >= wait) || (timeSinceLastCall < 0) || (maxing && (timeSinceLastInvoke >= maxWait));
  };

  var timerExpired = function timerExpired() {
    var time = $.now();
    if (shouldInvoke(time)) {
      return trailingEdge(time);
    }
    // Restart the timer.
    timerId = setTimeout(timerExpired, remainingWait(time));
  };

  var trailingEdge = function trailingEdge(time) {
    timerId = undefined;
    // Only invoke if we have `lastArgs` which means `func` has been
    // debounced at least once.
    if (trailing && lastArgs) {
      return invokeFunc(time);
    }
    lastArgs = (lastThis = undefined);
    return result;
  };

  var cancel = function cancel() {
    if (timerId !== undefined) {
      clearTimeout(timerId);
    }
    lastInvokeTime = 0;
    lastArgs = (lastCallTime = (lastThis = (timerId = undefined)));
  };

  var flush = function flush() {
    if (timerId === undefined) { return result; } else { return trailingEdge($.now()); }
  };

  var debounced = function debounced() {
    var time = $.now();
    var isInvoking = shouldInvoke(time);
    lastArgs = arguments;
    lastThis = this;
    lastCallTime = time;
    if (isInvoking) {
      if (timerId === undefined) {
        return leadingEdge(lastCallTime);
      }
      if (maxing) {
        // Handle invocations in a tight loop.
        timerId = setTimeout(timerExpired, wait);
        return invokeFunc(lastCallTime);
      }
    }
    if (timerId === undefined) {
      timerId = setTimeout(timerExpired, wait);
    }
    return result;
  };

  wait = parseInt(wait) || 0;
  debounced.cancel = cancel;
  debounced.flush = flush;
  return debounced;
};

// Textarea and select clone() bug workaround | Spencer Tipping
// Licensed under the terms of the MIT source code license
// https://github.com/spencertipping/jquery.fix.clone/blob/master/jquery.fix.clone.js

(function(original) {
  return jQuery.fn.clone = function() {
    var i;
    var result           = original.apply(this, arguments);
    var my_textareas     = this.find('textarea').add(this.filter('textarea'));
    var result_textareas = result.find('textarea').add(result.filter('textarea'));
    var my_selects       = this.find('select').add(this.filter('select'));
    var result_selects   = result.find('select').add(result.filter('select'));

    for (i = 0; i < my_textareas.length; i++) {
      var textarea = my_textareas[i];
      $(result_textareas[i]).val($(my_textareas[i]).val());
    }
    for (i = 0; i < my_selects.length; i++) {
      var select = my_selects[i];
      result_selects[i].selectedIndex = my_selects[i].selectedIndex;
    }
    return result;
  };
})(jQuery.fn.clone);

// https://gist.github.com/mythz/1214750#file-luhn-algorithm-coffee
$.luhnCheck = function(cardNumber) {
  if (!cardNumber) { return false; }
  var sum = 0;
  var alt = false;
  for (var i = cardNumber.length - 1; i >= 0; i--) {
    // Get the next digit.
    var num = parseInt(cardNumber.charAt(i), 10);
    // If it's not a valid number, abort.
    if (isNaN(num)) { return false; }
    // If it's an alternate number...
    if (alt) {
      num *= 2;
      if (num > 9) { num = (num % 10) + 1; }
    }
    // Flip the alternate bit.
    alt = !alt;
    // Add to the rest of the sum.
    sum += num;
  }
  // Determine if it's valid.
  return (sum % 10) === 0;
};

$.getCookie = function(cname) {
  var name = cname + '=';
  var ca = document.cookie.split(';');
  var i = 0;
  while (i < ca.length) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
    i++;
  }
  return '';
};
