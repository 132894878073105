/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$.fn.preserveVal = function(key) {
    if (key == null) { key = ''; }
    if ($(this).data("original-value-".concat(key)) == null) { return $(this).data("original-value-".concat(key), $(this).val()); }
  };

$.fn.isPreservedVal = function(key) {
  if (key == null) { key = ''; }
  return !$(this).is(function() {
    return ($(this).data("original-value-".concat(key)) != null) && ($(this).data("original-value-".concat(key)) !== $(this).val());
  });
};

$.fn.clearPreservedVal = function(key) {
  if (key == null) { key = ''; }
  return $(this).removeData("original-value-".concat(key));
};


$.fn.blank = function() { return $(this).is(function() { return $(this).val() === ''; }); };
$.fn.present = function() { return $(this).is(function() { return $(this).val() !== ''; }); };

$(function() {
  $('[data-remote="true"]').bind("ajax:beforeSend", function(e, xhr, settings) {
    var submitButton = $(this).find("input[name=\"commit\"]");

    $(this).find(".field-error").addClass("in");

    submitButton.data("original-text", $(this).text());
    return submitButton.text("Submitting...");
  });

  return $('body').on('change', '.checkbox.remote', function() {
    var input = $(this).find('[type="checkbox"]');
    var value = input.is(':checked');
    if (input.attr('value') === '0') { value = !value; }
    var data = {};
    data[input.attr('name')] = value ? '1' : '0';
    return $.post(input.data('action'), data);
  });
});
