$(() => {
  const $passwordField = $('.js-password-field');
  const $passwordToggleHide = $('.js-password-hide');
  const $passwordToggleShow = $('.js-password-show');

  $passwordToggleHide.on('click', () => {
    $passwordField.attr('type', 'password');
    $passwordToggleHide.hide();
    $passwordToggleShow.show();
  });

  $passwordToggleShow.on('click', () => {
    $passwordField.attr('type', 'text');
    $passwordToggleHide.show();
    $passwordToggleShow.hide();
  });
});
