/* eslint-disable camelcase */
/* eslint-disable func-names */
$(() => {
  const $joinForm = $('.js-payment-page').length > 0;
  const $giftForm = $('.js-gift-form').length > 0;
  const $joinFormB = $('.js-join-page-b').length > 0;

  if (!$joinForm && !$giftForm && !$joinFormB) { return; }

  // *****
  // * ELEMENT SELECTORS
  // *****

  // summary display elements
  const $summaryAmount = $('.js-summary-amount');
  const $summaryTotal = $('.js-summary-total');
  const $summaryPromoValue = $('.js-summary-promo-value');
  const $summaryPromoRow = $('.js-summary-promo-row');

  // applying promo code
  const $promoCodeInput = $('.js-promo-input');
  const $promoCodeInputIcon = $('.js-promo-icon');
  const $promoCodeApplyButton = $('.js-apply-promo');
  const $promoCodeMessage = $('.js-promo-message');

  // form fields
  const $membershipProductId = $('.js-promo-product-id');
  const $membershipReceivesMagazine = $('.js-receives-magazine');
  const $promoCodeId = $('.js-promo-code-id');
  const $promoCodeDiscount = $('.js-promo-code-discount');

  // errors
  // getter because this element doesn't always exist
  const getPromoCodeIdError = () => ($('.js-promo-id-error'));

  const canApply = () => (
    $promoCodeApplyButton.text().includes('Apply')
  );

  // *****
  // * CREATE FUNCTIONS
  // *****

  const formatPrice = (price) => (
    `$${parseFloat(price).toFixed(2).toString().replace(/\.00$/, '')}`
  );

  const clearPromoData = (clearInput = false) => {
    $promoCodeId.val('');
    $promoCodeDiscount.val(0);
    $summaryPromoRow.hide();
    $summaryPromoValue.text('');

    if ($joinForm) {
      $summaryTotal.text($summaryAmount.text());
    } else {
      $('html').trigger('gift.summary.update');
      $('html').trigger('join.summary.update');
    }

    $promoCodeApplyButton.text('Apply');

    $promoCodeMessage.hide();
    $promoCodeMessage.text('');
    $promoCodeMessage.removeClass('summary-valid');
    $promoCodeMessage.removeClass('summary-error');

    $promoCodeInput.prop('readonly', false);
    $promoCodeInput.removeClass('valid');
    $promoCodeInput.removeClass('error');
    $promoCodeInput.removeClass('loading');

    $promoCodeInputIcon.removeClass('valid');
    $promoCodeInputIcon.removeClass('loading');
    $promoCodeInputIcon.removeClass('filled');

    $promoCodeApplyButton.removeClass('disabled');
    $('#membership-form-promo-code-id-error').hide();

    if (clearInput) {
      $promoCodeInput.val('');
      handlePromoInputChanged();
    }

    if ($promoCodeInput.val().length > 0) { $promoCodeInputIcon.addClass('filled'); }
  };

  const setValidPromoCode = (response) => {
    const message = response.success_message;
    const { discount_price, total_price, promo_code } = response;

    $promoCodeId.val(promo_code);
    $promoCodeDiscount.val(discount_price);

    if ($joinForm) {
      $summaryTotal.text(formatPrice(total_price / 100));
      $summaryPromoValue.text(`-${formatPrice(discount_price / 100)}`);
    } else {
      $('html').trigger('gift.summary.update');
      $('html').trigger('join.summary.update');
    }

    $promoCodeApplyButton.text('Remove');

    $summaryPromoRow.show();

    $promoCodeMessage.html(message);
    $promoCodeMessage.addClass('summary-valid');
    $promoCodeMessage.show();

    $promoCodeInput.prop('readonly', true);
    $promoCodeInput.addClass('valid');
    $promoCodeInputIcon.addClass('valid');
  };

  const setInvalidPromoCode = (response) => {
    clearPromoData();
    $promoCodeInput.addClass('error');
    if (!$.isEmptyObject(response)) {
      $promoCodeMessage.html(response.error_message);
      $promoCodeMessage.addClass('summary-error');
      $promoCodeMessage.show();
    }
  };

  const applyPromoCode = () => {
    const code = $promoCodeInput.val();
    const product = $membershipProductId.val();
    const magazine = $membershipReceivesMagazine.val();
    // Only for the gifting form
    const recipientCount = $('.js-gift-recipient:visible').length;

    $.ajax({
      type: 'GET',
      url: $('[data-apply-promo-url]').data('apply-promo-url'),
      data: {
        promo_code: code,
        membership_product_id: product,
        has_magazine: magazine,
        gift: $giftForm,
        quantity: recipientCount
      },
      success(response) {
        clearPromoData();
        if (response.valid_promo) {
          setValidPromoCode(response);
        } else {
          setInvalidPromoCode(response);
        }
      },
      error(e) {
        clearPromoData(true);
        console.log(e);
      }
    });
  };

  const updatePromoDetails = () => {
    if ($promoCodeId.val().length > 0) {
      applyPromoCode();
    } else {
      clearPromoData();
    }
  };

  // *****
  // * LISTENER FUNCTIONS
  // *****

  const handlePromoInputChanged = () => {
    getPromoCodeIdError().hide();
    $promoCodeMessage.hide();
    $promoCodeInput.removeClass('valid');
    $promoCodeInput.removeClass('error');
    if ($promoCodeInput.val().length > 0) {
      $promoCodeApplyButton.removeClass('disabled');
      $promoCodeInputIcon.addClass('filled');
    } else {
      $promoCodeApplyButton.addClass('disabled');
      $promoCodeInputIcon.removeClass('filled');
    }
  };

  const handlePromoCodeKeydown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (canApply()) {
        $promoCodeApplyButton.trigger('click');
      }
    }
  };

  const handlePromoCodeBlur = () => {
    const isError = $promoCodeInput.hasClass('error');
    const isValid = $promoCodeInput.hasClass('valid');
    if ((isError && isValid) || canApply()) {
      $promoCodeInput.removeClass('valid');
    }
  };

  const handlePromoIconClick = () => {
    if ($promoCodeInputIcon.hasClass('filled')) {
      clearPromoData(true);
    }
  };

  const handlePromoApplyClick = (e) => {
    e.preventDefault();
    e.stopImmediatePropagation();

    $promoCodeInputIcon.removeClass('filled');
    if (canApply()) {
      if (!$promoCodeApplyButton.hasClass('disabled')) {
        $promoCodeInput.addClass('loading');
        $promoCodeInputIcon.addClass('loading');
        setTimeout((() => applyPromoCode()), 1500);
      }
    } else {
      clearPromoData(true);
    }

    return false;
  };

  // *****
  // * INIT
  // *****

  // Listeners
  $promoCodeInput.on('keyup', handlePromoInputChanged);
  $promoCodeInput.on('keydown', handlePromoCodeKeydown);
  $promoCodeInput.on('blur', handlePromoCodeBlur);
  $promoCodeInputIcon.on('click', handlePromoIconClick);
  $promoCodeApplyButton.on('click', handlePromoApplyClick);

  // Make available globally
  window.promoCodes = {
    updatePromoDetails,
    setInvalidPromoCode
  };

  $('html').trigger('promocodes.ready');
});
