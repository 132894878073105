/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(window).on('load', function() {
  $('.component-richtext figure.image img').each(function(index) {
    var figureimgw = $(this).width();
    return $(this).parent().css({'max-width': figureimgw + 'px'});
  });
});
