$(() => {
  const $accountPage = $('.js-account');
  if (!$accountPage.length) { return; }

  // ELEMENTS
  const $addMagSection = $('.js-account-mag');
  const $addMagBtn = $('.js-account-mag-add');
  const $addMagDetails = $('.js-account-mag-details');
  const $addMagChevron = $('.js-account-mag-chevron');
  const $addMagMessage = $('.js-account-mag-message');
  const $addAddressBtn = $('.js-account-address-add');
  const $editProfileSection = $('#member_data_form');
  const $addressInput = $('.js-account-address-input');
  const $membershipSectionToggle = $('#my-membership').find('.accordian-wrap-header');
  const $profileSectionToggle = $('#my-profile').find('.accordian-wrap-header');
  const $anchorElem = $(`${window.location.hash} > .accordian-wrap-header`);

  // LISTENER FUNCTIONS
  const handleAddMag = () => {
    $addMagDetails.slideToggle();
    $addMagChevron.toggleClass('active');

    // Open and scroll to
    if ($addMagChevron.hasClass('active')) {
      setTimeout(() => $.scrollTo($addMagSection), 150);
    }
  };

  const handleAddAddress = () => {
    // Required for mobile - open section
    $profileSectionToggle.trigger('click');

    // Show edit fields
    $editProfileSection.removeClass('js-dn');
    $.scrollTo($editProfileSection);

    // Focus main address finder input field
    setTimeout(() => { $addressInput.trigger('focus'); }, 150);
  };

  // INIT LISTENERS
  $addMagBtn.on('click', handleAddMag);
  $addAddressBtn.on('click', handleAddAddress);

  if ($addMagMessage.length > 0) {
    // Required for mobile - open section
    $membershipSectionToggle.trigger('click');

    // Scroll to the mag section
    $.scrollTo($addMagSection);
  }

  // Open accordion section on mobile
  if ($anchorElem.length > 0) {
    $anchorElem.trigger('click');
  }
});
