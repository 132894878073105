var menuOffset = -100;

$.scrollTo = function(selector, offset, speed) {
  offset = offset || menuOffset;
  speed = speed || 400;

  $('html, body').animate({
    scrollTop: $(selector).first().offset().top + offset
  }, speed);
};
