/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(function() {
  return $('a[data-popup=true]').click(function(evt) {
    var dimensions, height, width;
    evt.preventDefault();

    if (dimensions = $(this).attr('data-popup-dimensions')) {
      width = dimensions.split('x')[0];
      height = dimensions.split('x')[1];
    } else {
      width = 200;
      height = 200;
    }

    window.open($(this).attr('href'), 'social-share-dialog', "width=".concat(width, ",height=").concat(height));

    return false;
  });
});