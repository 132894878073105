// Stub for Modernizr features required by Foundation
window.Modernizr = {	
  touch: (Array.from(window).includes('ontouchstart')) || (window.DocumentTouch && document instanceof DocumentTouch)	
};

$(document).foundation();
$(document).watch_for_keyboard();
$('input, textarea').placeholder(); // we probably need to do this on any html inserted by ajax methods.
$('select').disguise_select();

// Trigger event for interactives JS - see app/views/shared/_content_blocks.html.erb
const event = document.createEvent('Event');
event.initEvent('consumer.javascript.ready', true, true);
document.documentElement.dispatchEvent(event);
