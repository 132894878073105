/* eslint-disable func-names */
$('html').on('promocodes.ready', () => {
  const hasForm = $('.JoinPage').length > 0;
  if (!hasForm) { return; }

  // *****
  // * Variables
  // *****

  // Class names
  const selectOptionClass = '.js-join-option-select';
  const optionButtonClass = '.js-join-option-btn';
  const selectOptionButtonClass = `${selectOptionClass} ${optionButtonClass}`;
  const selectOptionClassWide = 'JoinTypes-option--wide';
  const selectOptionClassTall = 'JoinTypes-option--tall';
  const displayOptionClass = '.js-join-display-option';
  const optionTermClassActive = 'Button-white--active';
  const optionTermButtonClass = '.js-join-term-btn';

  // Defaults
  const kinds = ['online', 'magazine', 'digital'];

  // *****
  // * ELEMENT SELECTORS
  // *****

  // Buttons
  const $selectOptionButtons = $(selectOptionButtonClass);
  const $optionTermButtons = $(optionTermButtonClass);

  // Page elements
  const $form = $('.js-join-form');
  const $formMain = $('.js-join-form-main');
  const $selectOptions = $(selectOptionClass);
  const $contactNumberSection = $('.js-join-contact-section');
  const $summaryStart = $('.js-join-summary-start');

  // Hidden form fields
  const $hiddenProductIdField = $('.js-promo-product-id');
  const $hiddenMagazineField = $('.js-receives-magazine');
  const $promoCodeDiscount = $('.js-promo-code-discount');

  // Form fields
  const $contactField = $('.js-join-contact-field');

  // Summary labels + values
  const $summaryFrequencyValue = $('.js-join-summary-frequency-value');
  const $summaryAmountValue = $('.js-join-summary-amount-value');
  const $summaryPromoValue = $('.js-summary-promo-value');
  const $summaryTotalValue = $('.js-join-summary-total-value');

  // Variables
  const digitalProductId = $form.data('digital-product-id');
  const digitalProductPrice = $form.data('digital-product-price');
  const digitalProductFrequency = $form.data('digital-product-frequency');

  // *****
  // * GETTER FUNCTIONS
  // *****

  const getSelectedTypeOption = () => (
    $(`${displayOptionClass}:visible`)
  );

  const getSelectedTermOption = () => (
    $(`${optionTermButtonClass}.${optionTermClassActive}:visible`)
  );

  const hasMag = () => (
    $hiddenMagazineField.val() === 'true'
  );

  const getDiscount = () => (
    $promoCodeDiscount.val()
  );

  const getPrice = () => {
    const termOption = getSelectedTermOption();

    if (!termOption.length) {
      return digitalProductPrice;
    }

    const membershipPrice = termOption.data('membership-price');

    if (hasMag()) {
      const magazinePrice = termOption.data('magazine-price');
      return membershipPrice + magazinePrice;
    }

    return membershipPrice;
  };

  const getFrequency = () => {
    const termOption = getSelectedTermOption();

    if (!termOption.length) {
      return digitalProductFrequency;
    }

    return termOption.data('summary-frequency');
  };

  // *****
  // * GENERAL FUNCTIONS
  // *****

  const formatPrice = (price) => (
    `$${(parseFloat(price) / 100).toFixed(2).toString().replace(/\.00$/, '')}`
  );

  const updateSummary = (checkPromo = false) => {
    // compare to true so we don't treat an event object as true
    if (checkPromo === true) {
      window.promoCodes.updatePromoDetails();
    }

    const frequency = getFrequency();
    const price = getPrice();
    const discount = getDiscount();
    const total = price - discount;

    $summaryFrequencyValue.text(frequency);
    $summaryAmountValue.text(formatPrice(price));
    $summaryPromoValue.text(`-${formatPrice(discount)}`);
    $summaryTotalValue.text(formatPrice(total));
  };

  const toggleContactSection = (show) => {
    if (show) {
      $contactField.removeAttr('disabled');
      $contactNumberSection.slideDown();
    } else {
      $contactNumberSection.slideUp();
      $contactField.attr('disabled', 'disabled');
    }
  };

  const showAndFillForm = () => {
    // Turn off animations so it doesn't jump around
    $.fx.off = true;

    const productId = $hiddenProductIdField.val();

    const isDigital = productId === digitalProductId;

    // Select type option
    let kind = 'online';
    if (isDigital) {
      kind = 'digital';
    } else if (hasMag()) {
      kind = 'magazine';
    }

    const $typeOption = $(`${selectOptionClass}[data-option-kind="${kind}"]`);
    const $typeButton = $typeOption.find(optionButtonClass);
    $typeButton.trigger('click', false);

    // No term option has been selected yet
    if (!productId) {
      $.fx.off = false;
      return;
    }

    // Select term option
    if (!isDigital) {
      const $termButton = $(`${optionTermButtonClass}[data-membership-product="${productId}"]`);
      $termButton.trigger('click');
    }

    // Turn animations back on
    // Scroll to the server error message
    setTimeout(() => {
      $.fx.off = false;
      $.scrollTo($summaryStart);
    }, 300);
  };

  // *****
  // * LISTENER FUNCTIONS
  // *****

  const handleSelectOptionType = (e, scrollToTerms = true) => {
    // Change to wide view (desktop)
    $selectOptions.removeClass(selectOptionClassTall);
    $selectOptions.addClass(selectOptionClassWide);

    // Deselect term options
    $optionTermButtons.removeClass(optionTermClassActive);

    const selectedKind = $(e.currentTarget)
      .closest(selectOptionClass)
      .data('option-kind');

    // Show/hide relevant sections
    // Eg: js-join-digital-hide, js-join-digital-show, etc
    kinds.forEach((kind) => {
      if (kind === selectedKind) {
        $(`.js-join-${kind}-hide`).hide();
        $(`.js-join-${kind}-show`).show();
      } else {
        $(`.js-join-${kind}-hide`).show();
        $(`.js-join-${kind}-show`).hide();
      }
    });

    // Update magazine
    const hasMagazine = selectedKind === 'magazine';
    $hiddenMagazineField.val(hasMagazine);

    // Update digital pass
    const isDigital = selectedKind === 'digital';
    if (isDigital) {
      // Open main form - no term selection for digital
      $hiddenProductIdField.val(digitalProductId);
      toggleContactSection(false);
      $formMain.slideDown(400);
    } else {
      // Hide main form - ready for user to pick term
      $formMain.hide();
    }

    // Show the form
    $form.fadeIn();
    if (scrollToTerms) {
      $.scrollTo(getSelectedTypeOption());
    }
    updateSummary(true);
  };

  const handleSelectOptionTerm = (e) => {
    $optionTermButtons.removeClass(optionTermClassActive);

    const $selectedTerm = $(e.currentTarget);
    $selectedTerm.addClass(optionTermClassActive);

    const selectedProductId = $selectedTerm.data('membership-product');
    $hiddenProductIdField.val(selectedProductId);

    const showContactSection = $selectedTerm.data('show-contact-section');
    toggleContactSection(showContactSection);

    $formMain.slideDown(400);
    setTimeout(() => $.scrollTo($formMain), 150);
    updateSummary(true);
  };

  // *****
  // * INIT
  // *****

  // Membership option type: online, magazine, digital
  $selectOptionButtons.on('click', handleSelectOptionType);

  // Membership option term: 3 month, 6 month, 1 year, etc
  $optionTermButtons.on('click', handleSelectOptionTerm);

  // Trigger from promo code JS
  $('html').on('join.summary.update', updateSummary);

  if ($('.js-join-show-form').length > 0) {
    showAndFillForm();
  }
});
