/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Foundation's Magellan should be doing this, but doesn't, and it will be quicker for me to write this from scratch than fight with Magellan.

var update_frequency = 10;
var update_events = 'scroll resize orientationchange touchstart touchend ready';

var tocIsVisible = function tocIsVisible() {
  return $('.toc:visible').length !== 0;
};

$.fn.watchMenu = function() {
  var offset = 55;

  return $(this).each(function() {
    var menu = $(this).closest('ul');
    var menuItems = menu.find('a[href^="#"]');
    var targets = menuItems.map(function() { return $(this).getTarget(); });

    var highlightCurrent = function highlightCurrent() {
      if (tocIsVisible()) {
        var scrollPosition = $(window).scrollTop() + offset;
        var currentTarget = undefined;
        targets.each(function() {
          var targetTop = $(this).offset().top;
          if ((targetTop < scrollPosition) && ((currentTarget == null) || (targetTop > currentTarget.offset().top))) {
            return currentTarget = this;
          }
        });
        menuItems.removeClass('active');
        if (currentTarget != null) {
          return menuItems.filter("[href=\"#".concat(currentTarget.attr('id'), "\"]")).addClass('active');
        }
      }
    };
    var highlightUpdater = window.throttle(highlightCurrent, update_frequency);
    return $(window).on(update_events, highlightUpdater);
  });
};

$('.toc').watchMenu();

$.fn.scrollWith = function() {
  var offset = 50;
  if (tocIsVisible()) {
    return $(this).each(function() {
      if (($(this).data('defaultPosition') == null) || !($(this).data('defaultPosition') > 0)) {
        $(this).data('defaultPosition', $(this).offset().top);
      }

      var section = $(this).closest('.js-sticky-container');
      var scrollPosition = $(window).scrollTop();
      var defaultPosition = $(this).data('defaultPosition');

      if (defaultPosition >= scrollPosition) {
        $(this).css({position: 'relative', top:0, bottom: 'auto'})
          .removeClass('fixed')
          .removeClass('bottom');
        // If for some reason this is fixed higher than it should (e.g. an image loaded).
        // I assume the new position is correct, and run again.
        if (defaultPosition !== $(this).offset().top) {
          $(this).data('defaultPosition', $(this).offset().top);
          return $(this).scrollWith();
        }
      } else if ((section.offsetBottom() - $(this).height()) < scrollPosition) {
        return $(this).css({position:'absolute', top:'auto', bottom: 0})
          .removeClass('fixed')
          .addClass('bottom');
      } else {
        $(this).width($(this).parent().width());
        return $(this).css({position:'fixed', top:offset, bottom:'auto'})
         .addClass('fixed')
         .removeClass('bottom');
      }
    });
  }
};

var fixedUpdater = window.throttle((function() {
  return $('.js-sticky').scrollWith();
}), update_frequency);
$(window).on(update_events, fixedUpdater);

// Another wonderful example, jeeezus
$(function() {
  var $sticky = $('.js-sticky');
  setInterval(( function() {
    return $sticky.scrollWith();
  }), 300);

  // Interactives have hard coded headings that need ids for the TOC to work.
  return $('h2.title').each(function() {
    if (!this.hasAttribute('id')) {
      return this.setAttribute('id', this.innerHTML.toLowerCase().replace(/[ ]/g, '-').replace(/[^a-z0-9\-]/g, ''));
    }
  });
});
