/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
//= require jquery.payment

$(function() {
  $('#CardNumber').payment('formatCardNumber');
  $('#Cvc2').payment('formatCardCVC');

  return $('#payment_form').submit(function(e){ 
    var valid_expiry_date = $.payment.validateCardExpiry($('#ExpiryMonth').val(), $('#ExpiryYear').val());
    var valid_card_number = $.payment.validateCardNumber($('#CardNumber').val()); 
    var valid_cvc = $.payment.validateCardCVC($('#Cvc2').val());
    if (!valid_card_number || !valid_cvc || !valid_expiry_date) {
      e.preventDefault();
      if (!valid_card_number) {
        $('#cc-error').remove();
        $('#CardNumber').parent().append('<small class="error" id="cc-error">Invalid Credit Card Number</small>');
      } else {
        $('#cc-error').remove();
      }

      if (!valid_expiry_date) {
        $('#date-error').remove();
        $('#ExpiryMonth').parent().append('<small class="error" id="date-error">Invalid Date</small>');
      } else {
        $('#date-error').remove();
      }

      if (!valid_cvc) {
        $('#cvc-error').remove();
        return $('#Cvc2').parent().append('<small class="error" id="cvc-error">Invalid CVC</small>');
      } else {
        return $('#cvc-error').remove();
      }
    } else {
      $('#cc-error').remove();
      $('#date-error').remove();
      return $('#cvc-error').remove();
    }
  });
});