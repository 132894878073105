const menuableHeadings = [];

function stickSidebarMenu() {
  const sidebarWrapper = $('.sidebar-menu-wrapper');

  if (!sidebarWrapper.length) return;

  const pageHeight = $(document).height();
  const wrapperDistanceFromTop = sidebarWrapper.offset().top;
  const wrapperHeight = sidebarWrapper.height();
  const wrapperDistanceFromBottom = pageHeight - (wrapperDistanceFromTop + wrapperHeight);

  $('#sidebar-menu').sticky({
    topSpacing: 100,
    bottomSpacing: wrapperDistanceFromBottom + 50
  });
}

function updateTopOffsetsForSubsequentMenuableHeadings(index, value, increasing) {
  for (let i = index + 1; i < menuableHeadings.length; i++) {
    const menuableHeading = menuableHeadings[i];
    if (increasing) menuableHeading.offsetFromTop += value;
    else menuableHeading.offsetFromTop -= value;
  }
}

// Responsible for reacting to click events on h3 elems that expand accordions
function updateTopOffsets(e) {
  // Note that the ordering of operations is delicate - Do all operations before removing the open-faq class,
  // Or add it first if the class is not present - Failure to do so will result in the jQuery selector
  // not selecting anything
  const accordionHeading = $(e.target);
  const accordionSuperHeading = accordionHeading.closest('.faq');
  const accordionContent = accordionHeading.next('.faq-content');
  const isClosing = accordionContent.hasClass('open-faq');
  const menuHeading = menuableHeadings.find((s) => s.divID === accordionSuperHeading.attr('id'));

  // Get height before closing, negative value
  let heightChange = -accordionContent.outerHeight();

  if (isClosing) {
    accordionContent.removeClass('open-faq');
    accordionHeading.removeClass('faq-heading-open');
  } else {
    accordionContent.addClass('open-faq');
    accordionHeading.addClass('faq-heading-open');
    // update height change after opening, positive value
    heightChange = accordionContent.outerHeight();
  }

  menuHeading.offsetFromTop += heightChange;

  // Updating the top offset of all menuableHeadings after the one in question
  updateTopOffsetsForSubsequentMenuableHeadings(menuableHeadings.indexOf(menuHeading), heightChange, !isClosing);
}

function addMenuItems(sidebarMenuItems) {
  const menuableContent = $('[data-sidebar-menu-item="true"]');

  menuableContent.each((i, heading) => {
    const headingText = $(heading).data('sidebar-menu-heading') || heading.textContent;
    const headingHref = $(heading).data('sidebar-menu-anchor') || heading.id;

    const subheading = $(heading).data('sidebar-menu-subheading');

    const menuItem = $(`
      <li class="sidebar-menu-item ${subheading ? 'sidebar-menu-item--sub' : 'sidebar-menu-item--super'}">
        <a href="#${headingHref}">${headingText}</a>
      </li>
    `);

    menuableHeadings.push({
      title: headingText,
      offsetFromTop: $(heading).offset().top,
      sidebarAnchor: menuItem,
      divID: headingHref || headingText.replace(/\W/g, '-'),
      collapsibleContentHeight: 0
    });

    sidebarMenuItems.push(menuItem);
  });
}

function wrapAccordions() {
  // Required to make accordion content selectable, eg:
  // <div class="faq">
  //    <h3>Accordion heading</h3>
  //    <div>Accordion content</div> <-- adds class so we can select here
  // </div>
  if ($('.content-block').hasClass('faq')) {
    const accordionContentElements = $('.faq h3 + h4, .faq h3 + p, .faq h3 + ul, .faq h3 + ol, .faq h3 + figure');
    accordionContentElements.each((i, accordionContentElement) => {
      $(accordionContentElement).nextUntil('h3').addBack().wrapAll('<div class=\'faq-content\' />');
    });
  }
}

function calculateAccordionHeights() {
  const accordionContents = $('.faq-content');

  // Open each accordion to find the content height
  accordionContents.addClass('open-faq');

  // Obtaining the total height of all collapsible content for each of the menuableHeadings
  accordionContents.each((i, accordionContentItem) => {
    const accordionSuperHeading = accordionContentItem.closest('.faq');
    const menuableHeading = menuableHeadings.find((s) => s.divID === accordionSuperHeading.id);
    if (!menuableHeading) return; // If there is no menuableHeading, leave
    menuableHeading.collapsibleContentHeight += $(accordionContentItem).outerHeight(true); // To take into account vertical padding and margins, use outerHeight(true)
  });

  // Updating top offset for each menuableHeading based off the obtained collapsibleContentHeight values
  for (let i = 0; i < menuableHeadings.length; i++) {
    const menuableHeading = menuableHeadings[i];
    if (menuableHeading.collapsibleContentHeight === 0) continue; // If there is no collapsible content for this menuableHeading, skip
    updateTopOffsetsForSubsequentMenuableHeadings(i, menuableHeading.collapsibleContentHeight, false);
  }

  // Close each accordion again
  accordionContents.removeClass('open-faq');

  // Listener for future accordion toggles
  const accordionHeadings = $('.faq h3');
  accordionHeadings.on('click', updateTopOffsets);
}

function buildSidebarMenu() {
  const sidebarMenu = $('.sidebar-menu-list');
  const sidebarMenuItems = [];

  addMenuItems(sidebarMenuItems);
  wrapAccordions();
  calculateAccordionHeights(menuableHeadings);

  if (sidebarMenuItems.length) {
    sidebarMenu.append($(`
      <li class="sidebar-menu-heading">
        On this page
      </li>
    `));

    sidebarMenu.append(sidebarMenuItems);
  }
}

function addSidebarListeners() {
  // Scroll event listener that highlights an anchor link on the sidebar that corresponds to the
  // most recently passed menuableHeading on the article
  $(document).on('scroll', () => {
    // Determines when a menuableHeading has been 'passed' via scroll
    const currentHeadingRegion = 200;
    const currentScrollDistanceFromTop = $(window).scrollTop();
    menuableHeadings.forEach((menuableHeading) => {
      const dist = menuableHeading.offsetFromTop - currentScrollDistanceFromTop;
      // If the scroll distance lies within this menuableHeading's assigned region, highlight it
      if (dist > (currentHeadingRegion * -1) && dist < currentHeadingRegion) {
        $('.sidebar-menu-item--active').removeClass('sidebar-menu-item--active');
        menuableHeading.sidebarAnchor.addClass('sidebar-menu-item--active');
      }
    });
  });
}

$(() => {
  $(location.hash).addClass('wrap-rm-open');

  if ($('#sidebar-menu').length > 0) {
    buildSidebarMenu();
    stickSidebarMenu();
    addSidebarListeners();
  }

  if ($('.faq').length > 0 && $('#sidebar-menu').length === 0) {
    wrapAccordions();
    calculateAccordionHeights();
  }
});
