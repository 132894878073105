/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$.reload = function(selector, callback, cacheBreaker) {
  var path = location.pathname;
  if (cacheBreaker) {
    path = path + '?cache=' + cacheBreaker;
  }

  return $(selector).load(
    path + ' ' + selector + ' > *', callback
  );
};
