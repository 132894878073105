/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(function() {
  $("[data-toggle-target]").on('click', function(e) {
    e.preventDefault();
    if ($("[data-toggle='" + $(this).data('toggle-target') + "']:visible").length > 0) {
      return $("[data-toggle='" + $(this).data('toggle-target') + "']").slideUp();
    } else {
      return $("[data-toggle='" + $(this).data('toggle-target') + "']").slideDown();
    }
  });

  // Open an accordion tab if it's anchor exists in the URL, used on the members account
  // page in tablet/phone layout when, for instance, cancelling a membership.
  if (window.location.hash) {
    var $target = $('body').find(window.location.hash);
    if ($target.parent().hasClass('accordion')) {
      $target.addClass('active');
      return $target.children().first().addClass('active');
    }
  }
});
