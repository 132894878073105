$(function() {
  function offsetAnchor(anchorId) {
    if ($(anchorId).length === 0) { return; }
    $.scrollTo(anchorId);
  }

  // on anchor link click
  $(document).on('click', 'a[href^="#"]', function(e) {
    let targetHref = e.target.getAttribute("href");
    if (targetHref.length === 1) { return; }
    offsetAnchor(targetHref);
  });

  // on page load
  if (location.hash.length > 1) {
    setTimeout(function() {
      offsetAnchor(location.hash);
    }, 250);
  }
});