/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(function() {
  return $('[data-character-count]').each(function() {
    var counter = $(this);
    var monitored_input = $("#".concat($(this).attr('data-character-count')));
    var maximum_characters = monitored_input.attr('maxlength') || 160;
    monitored_input.removeAttr('maxlength'); // don't want the built-in maxlength because it's done here
    monitored_input.on('keyup change', function() {
      // Newlines are not included in the character count
      var maximum_characters_ignoring_newlines = (parseInt(maximum_characters) + monitored_input.val().split("\n").length) - 1;
      var total_length = monitored_input.val().length;
      if (total_length > maximum_characters_ignoring_newlines) {
        counter.addClass('red').text("0 characters left");
        return monitored_input.val(monitored_input.val().substring(0, maximum_characters_ignoring_newlines));
      } else {
        counter.removeClass('red');
        return counter.text("".concat(maximum_characters_ignoring_newlines - total_length, " character").concat(total_length !== 1 ? 's' : undefined, " left"));
      }
    });
    return monitored_input.trigger('keyup');
  });
});
