/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(function() {
  $('#new_membership').submit(function(e) {
    if (!$('#membership_membership_product_id').val()) {
      e.preventDefault();
      $('#membership-error').remove();
      return $('#membership_membership_product_id').parent().append('<small class="error" id="membership-error">Please select a membership product to proceed</small>');
    } else {
      return $('#membership-error').remove();
    }
  });

  // toggles visibility on edit profile form
  return $("#my-profile-content").on('click', '#to_member_data_form', function() {
    return $('#member_data_form').toggleClass('js-dn');
  });
});
