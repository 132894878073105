$('html').on('promocodes.ready', () => {
  const $giftPage = $('.GiftPage');
  if ($giftPage.length <= 0) { return; }

  // *****
  // * Variables
  // *****
  // Defaults
  let recipientCount = 1;

  // Class names
  const recipientClass = '.js-gift-recipient';
  const recipientClearFieldsClass = '.js-gift-remove-clear';
  const optionClass = '.js-gift-option';
  const optionButtonClass = '.js-gift-option-btn';
  const optionClassWide = 'GiftTypes-option--wide';
  const optionClassActive = 'GiftTypes-option--active';
  const optionTermClassActive = 'GiftForm-term--active';
  const optionTermClass = '.js-gift-term-btn';
  const optionEmailedClassActive = 'GiftForm-emailed-option--active';
  const optionEmailedClass = '.js-gift-emailed-option';
  const emailedGiftShowClass = '.js-gift-emailed-show';

  // *****
  // * ELEMENT SELECTORS
  // *****

  // Buttons
  const $optionTypeButtons = $(optionButtonClass);
  const $optionTermButtons = $('.js-gift-term-btn');
  const $optionEmailedButtons = $('.js-gift-emailed-option');
  const $addRecipient = $('.js-gift-add-recipient');
  const $removeRecipient = $('.js-gift-remove-recipient');

  // Form fields
  const $hiddenMagazineField = $('.js-receives-magazine');
  const $membershipProductField = $('.js-promo-product-id');
  const $promoCodeDiscount = $('.js-promo-code-discount');
  const $recipientDateFields = $('.js-datepicker');

  // Fields to show/hide depending on if a magazine is selected
  const $magazineShowElems = $('.js-gift-mag-show');
  const $magazineHideElems = $('.js-gift-mag-hide');

  // Page elements
  const $form = $('.js-gift-form');
  const $options = $(optionClass);
  const $formMain = $('.js-gift-form-main');
  const $summaryStart = $('.js-gift-summary-start');

  // Summary labels + values
  const $membershipLabel = $('.js-gift-summary-membership-label');
  const $membershipPrice = $('.js-gift-summary-membership-price');
  const $quantityRow = $('.js-gift-summary-quantity-row');
  const $quantityValue = $('.js-gift-summary-quantity-value');
  const $promoRow = $('.js-summary-promo-row');
  const $promoValue = $('.js-summary-promo-value');
  const $totalValue = $('.js-gift-summary-total-value');

  // *****
  // * GETTER FUNCTIONS
  // *****

  // Get field for a specific recipient
  const getRecipientField = (fieldName, recipientIndex) => (
    $(`#recipients_${recipientIndex}_${fieldName}`)
  );

  const getLastRecipient = () => (
    $(`[data-recipient-index="${recipientCount - 1}"`)
  );

  const getSelectedTypeOption = () => (
    $(`.${optionClassActive}`)
  );

  const getSelectedTermOption = () => (
    $(`.${optionTermClassActive}`)
  );

  const hasMagazine = () => (
    getSelectedTypeOption().data('magazine')
  );

  // *****
  // * GENERAL FUNCTIONS
  // *****

  const formatPrice = (price) => (
    `$${(parseFloat(price) / 100).toFixed(2).toString().replace(/\.00$/, '')}`
  );

  const toggleRecipientActionButtons = () => {
    if (recipientCount < 5) {
      $addRecipient.show();
    } else {
      $addRecipient.hide();
    }

    if (recipientCount > 1) {
      const $lastRecipient = getLastRecipient();

      // Only show remove for the last recipient
      // This keeps the recipients in order and keeps things simple
      $removeRecipient.hide();
      $lastRecipient.find($removeRecipient).show();
    }
  };

  const updateSummary = (checkPromo = false) => {
    // compare to true so we don't treat an event object as true
    if (checkPromo === true) {
      window.promoCodes.updatePromoDetails();
    }

    // Get prices and labels
    const membershipPrice = getSelectedTermOption().data('membershipPrice');
    const magazinePrice = hasMagazine()
      ? getSelectedTermOption().data('magazinePrice')
      : 0;
    const membershipLabel = hasMagazine()
      ? getSelectedTermOption().data('summaryMagazineLabel')
      : getSelectedTermOption().data('summaryMembershipLabel');

    // Calculate costs
    const singleMembershipPrice = membershipPrice + magazinePrice;
    const totalDiscount = $promoCodeDiscount.val() * recipientCount;
    const totalPrice = (singleMembershipPrice * recipientCount) - totalDiscount;

    // Update text
    $membershipPrice.text(formatPrice(singleMembershipPrice));
    $quantityValue.text(recipientCount);
    $promoValue.text(`-${formatPrice(totalDiscount)}`);
    $totalValue.text(formatPrice(totalPrice));
    $membershipLabel.text(membershipLabel);

    // Hide/show promo discounts
    if (totalDiscount) {
      $promoRow.fadeIn();
    } else {
      $promoRow.fadeOut();
    }

    // Hide/show quanitity
    if (recipientCount > 1) {
      $quantityRow.fadeIn();
    } else {
      $quantityRow.fadeOut();
    }
  };

  const showAndFillForm = () => {
    // Turn off animations so it doesn't jump around
    $.fx.off = true;

    // Select type option
    const magValue = $hiddenMagazineField.val();
    const $magOption = $(`${optionClass}[data-magazine="${magValue}"]`);
    const $magSelect = $magOption.find(optionButtonClass);
    $magSelect.trigger('click');

    // Select term option
    const termValue = $membershipProductField.val();
    const $termOption = $(`${optionTermClass}[data-membership-product="${termValue}"]`);
    $termOption.trigger('click');

    // Turn animations back on
    // Scroll to the server error message
    setTimeout(() => {
      $.fx.off = false;
      $.scrollTo($summaryStart);
    }, 150);
  };

  // *****
  // * LISTENER FUNCTIONS
  // *****

  const handleSelectOptionType = (e) => {
    // Deselect any active options
    $options.removeClass(optionClassActive);

    // Change to wide view (desktop)
    $options.addClass(optionClassWide);

    // Set selected option as active
    const $selectedOption = $(e.currentTarget).closest(optionClass);
    $selectedOption.removeClass(optionClassWide);
    $selectedOption.addClass(optionClassActive);

    const magSelected = $selectedOption.data('magazine');

    if (magSelected) {
      $magazineShowElems.show();
      $magazineHideElems.hide();
    } else {
      $magazineShowElems.hide();
      $magazineHideElems.show();
    }

    // Update form field
    $hiddenMagazineField.val(magSelected);

    // Show the form
    $form.css({ paddingTop: $selectedOption.innerHeight() + 10 });
    $form.fadeIn();
    $.scrollTo($selectedOption);
    updateSummary(true);
  };

  const handleSelectOptionTerm = (e) => {
    $optionTermButtons.removeClass(optionTermClassActive);

    const $selectedTerm = $(e.currentTarget);
    $selectedTerm.addClass(optionTermClassActive);

    const selectedProductId = $selectedTerm.data('membershipProduct');
    $membershipProductField.val(selectedProductId);

    $formMain.slideDown(400);
    setTimeout(() => $.scrollTo($formMain), 150);
    updateSummary(true);
  };

  const handleSelectoptionEmailed = (e) => {
    const $selectedOption = $(e.currentTarget);
    const $recipient = $selectedOption.closest(recipientClass);
    const index = $recipient.data('recipientIndex');
    const isEmailed = !!$selectedOption.data('emailedGift');
    const $emailedGiftField = getRecipientField('emailed', index);

    // Set hidden field value (boolean)
    $emailedGiftField.val(isEmailed);

    // Set button active
    $recipient.find(optionEmailedClass).removeClass(optionEmailedClassActive);
    $selectedOption.addClass(optionEmailedClassActive);

    // Show/hide relevant fields
    if (isEmailed) {
      $recipient.find(emailedGiftShowClass).slideDown();
    } else {
      $recipient.find(emailedGiftShowClass).slideUp();
    }
  };

  const handleAddRecipient = (e) => {
    e.preventDefault();

    recipientCount += 1;

    const $newRecipient = getLastRecipient();
    $newRecipient.slideDown();

    toggleRecipientActionButtons();

    setTimeout(() => {
      $.scrollTo($newRecipient);
      $($newRecipient.find('input:text')[0]).trigger('focus');
    }, 150);

    // Refresh validations - include added recipient
    $('html').trigger('gift.form.rules.add', [$newRecipient.data('recipientIndex')]);

    updateSummary(true);
  };

  const handleRemoveRecipient = (e) => {
    $(e.currentTarget).hide();

    const $recipientToRemove = getLastRecipient();
    $recipientToRemove.slideUp();

    recipientCount -= 1;

    toggleRecipientActionButtons();

    const $lastRecipient = getLastRecipient();
    setTimeout(() => $.scrollTo($lastRecipient), 250);

    // Refresh validations - exclude removed recipient
    $('html').trigger('gift.form.rules.remove', [$recipientToRemove.data('recipientIndex')]);

    updateSummary(true);

    // Clear fields that are important to remove (email, first name, last name)
    // Date and booleans are fine to leave
    $recipientToRemove.find(recipientClearFieldsClass).each((i, field) => {
      $(field).val('');
    });
  };

  // *****
  // * INIT
  // *****

  $recipientDateFields.datepicker({
    dateFormat: 'dd/mm/yy',
    minDate: '0',
    maxDate: '+1y'
  });

  // Membership option type: magazine yes/no
  $optionTypeButtons.on('click', handleSelectOptionType);

  // Membership option term: 3 month, 6 month, 1 year, etc
  $optionTermButtons.on('click', handleSelectOptionTerm);

  // Option to be gifted by email: yes/no
  $optionEmailedButtons.on('click', handleSelectoptionEmailed);

  // Add/remove recipients
  $addRecipient.on('click', handleAddRecipient);
  $removeRecipient.on('click', handleRemoveRecipient);

  // Trigger from promo code JS
  $('html').on('gift.summary.update', updateSummary);

  if ($('.js-gift-show-form').length > 0) {
    showAndFillForm();
  }
});

// TODO.GIFT: REMOVE AFTER TESTING
// $('.js-gift-form').show();
// $('.js-gift-form-main').show();
