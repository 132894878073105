$(() => {
  const hasForm = $('.js-feedback-form').length > 0;
  if (!hasForm) { return; }

  // Display elements
  const feedbackWrapper = $('.js-feedback-form');
  const commentSection = $('.js-feedback-comment');
  const commentLabel = $('.js-feedback-comment-label');
  const likedIcon = $('.js-feedback-liked-icon');
  const dislikedIcon = $('.js-feedback-disliked-icon');
  const commentInputSection = $('.js-feedback-comment-inputs');

  // Input fields
  const likedInput = $('#feedback-liked');
  const dislikedInput = $('#feedback-disliked');
  const commentInput = $('#feedback-comment');
  const commentSubmitButton = $('.js-submit-comment');
  // Honeypot is used to check for bot submissions
  const honeypotInput = $('#feedback-flag');

  // Track the current feedback object
  let feedbackId;

  const displaySuccess = () => {
    if (!commentInputSection.hasClass('dn')) commentInputSection.addClass('dn');
    if (commentSection.hasClass('active')) commentSection.removeClass('active');
    commentLabel.text('Thank you for your feedback!');
  };

  const honeypotIsFilled = (finalSubmit) => {
    if (honeypotInput.val() !== '') {
      // If "submit" button clicked, display a success method to fool attackers
      if (finalSubmit) displaySuccess();
      return true;
    }
    return false;
  };

  const submitFeedback = (finalSubmit = false) => {
    // Check if our honeypot is filled; don't submit a record if it is
    if (honeypotIsFilled(finalSubmit)) return;

    const type = feedbackId ? 'PUT' : 'POST';
    const url = `${commentSubmitButton.data('feedbackUrl')}${feedbackId ? `/${feedbackId}` : ''}`;

    // Cap the length of comments, in case a longer comment somehow got through
    const maxCommentLength = commentInput.data('feedbackCommentMax');
    let comment = commentInput.val();
    if (comment.length > maxCommentLength) {
      comment = comment.substring(0, maxCommentLength);
    }

    const liked = likedInput.hasClass('selected');
    const data = {
      liked,
      comment,
      id: feedbackId,
      token: $('meta[name="csrf-token"]').attr('content'),
      tokenFromData: $('body').data('latestAuthToken')
    };

    // Check the page type and provide the appropriate ID based on that
    const pageType = feedbackWrapper.data('feedbackPageType');
    const pageID = feedbackWrapper.data('feedbackPageId');
    if (pageType === 'Article') {
      data.article_id = pageID;
    } else if (pageType === 'ProductLandingPage') {
      data.product_landing_page_id = pageID;
    }

    $.ajax({
      type,
      url,
      data,
      success(response) {
        feedbackId = response.id;
      }
    });

    // Track thumb clicks only
    if (!finalSubmit) {
      window.dataLayer.push({
        event: 'content_feedback_submitted',
        liked
      });
    }
  };

  // Add a debounce delay to avoid spam on submit
  const debouncedSubmit = window.debounce(submitFeedback, 2000);

  const showComment = () => {
    if (commentSection.hasClass('dn')) commentSection.removeClass('dn');
    if (!commentSection.hasClass('active')) commentSection.addClass('active');
    if (commentInputSection.hasClass('dn')) commentInputSection.removeClass('dn');
  };

  const selectLikedOption = (selected, selectedIcon, deselected, deselectedIcon, label) => {
    const inputChanged = !selected.hasClass('selected');
    if (!selected.hasClass('selected')) {
      selectedIcon.removeClass('outline');
      selectedIcon.addClass('solid');
      selected.addClass('selected');
    }
    if (deselected.hasClass('selected')) {
      deselectedIcon.removeClass('solid');
      deselectedIcon.addClass('outline');
      deselected.removeClass('selected');
    }
    // Only affect the comment and submit if the input actually changed
    if (inputChanged) {
      commentLabel.text(label);
      showComment();
      debouncedSubmit();
    }
  };

  likedInput.on('click', () => {
    selectLikedOption(likedInput, likedIcon, dislikedInput, dislikedIcon, 'Tell us what was helpful');
  });

  dislikedInput.on('click', () => {
    selectLikedOption(dislikedInput, dislikedIcon, likedInput, likedIcon, 'Tell us why this wasn\'t helpful');
  });

  commentSubmitButton.on('click', () => {
    displaySuccess();
    debouncedSubmit(true);
  });

  // If a user clicks anywhere in the comment area (other than 'submit'), focus the text input
  commentSection.on('click', () => {
    commentInput.focus();
  });
});
