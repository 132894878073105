/* eslint-disable no-alert */
$('html').on('session:pinged', () => {
  let create = false;
  if (!($('#comments').length > 0)) { return; }

  const show = function show() {
    $('[data-reply]').show();
    $('[data-overflow-comments]').show();
    $('[data-more-comments]').hide();
    create = true;
  };

  const bindShowMore = function bindShowMore() {
    $('[data-more-comments]').not('.click-bound').on('click', () => show());
    $('[data-more-comments]').addClass('click-bound');
  };

  bindShowMore();

  const setMetaInfo = function setMetaInfo(elm) {
    const metaInfo = document.createElement('input');
    metaInfo.type = 'hidden';
    metaInfo.name = 'comment[comment_id]';
    metaInfo.value = $(elm).data('comment-id');
    const commentForm = $('#new_comment');
    commentForm.append(metaInfo);
  };

  if (!($('[data-comment-modal]').length > 0)) { return; }

  const bindCreateComment = function bindCreateComment() {
    $('[data-create-comment]').on('click', () => {
      $('[data-create-comment]').hide();
      $('[data-comment-modal]').show();

      $('form#new_comment')[0].reset();
      const commentForm = $('#new_comment');
      commentForm.find('input[name=comment]').remove();
      commentForm.find('input[name=_method]').remove();
      commentForm[0].action = $('[data-create-path]').data('create-path');
      $('input#comment_base').prop('value', 'Submit comment');
      create = true;
    });

    $('[data-comment-modal-close]').on('click', () => {
      $('[data-comment-modal]').hide();
      $('[data-create-comment]').show();
    });
  };

  const bindEditComment = function bindEditComment(elm) {
    $(elm).find('[data-update-path]').first().parent()
      .show();
    $(elm).find('[data-update-path]').first().on('click', (e) => {
      $('[data-comment-modal]').show();
      $('[name="comment[body]"]').val(
        $(elm).closest('[data-comment-body]').data('comment-body')
      );

      const commentForm = $('#new_comment');
      const crudMethod = document.createElement('input');
      crudMethod.type = 'hidden';
      crudMethod.name = '_method';
      crudMethod.value = 'put';

      commentForm[0].action = $(e.target).data('update-path');
      commentForm.append(crudMethod);

      setMetaInfo(e.target);

      $('input#comment_base').prop('value', 'Update comment');
    });
  };

  const bindDeleteComment = function bindDeleteComment(elm) {
    $(elm).find('[data-delete-path]').first().parent()
      .show();
    $(elm).find('[data-delete-path]').first().off('click');
    $(elm).find('[data-delete-path]').first().on('click', (e) => {
      if (!confirm('Are you sure?')) {
        e.preventDefault();
      } else {
        const crudMethod = document.createElement('input');
        crudMethod.type = 'hidden';
        crudMethod.name = '_method';
        crudMethod.value = 'delete';

        const commentForm = $('#new_comment');
        commentForm[0].action = $(e.target).data('delete-path');
        commentForm.append(crudMethod);

        setMetaInfo(e.target);

        $('#new_comment').trigger('submit');
      }
    });
  };

  const bindReplyComment = function bindReplyComment() {
    $('[data-reply-path]').on('click', (e) => {
      $('[data-comment-modal]').show();

      const commentForm = $('#new_comment');
      commentForm[0].action = $('[data-reply-path]').data('reply-path');

      setMetaInfo(e.target);

      create = true;

      $('input#comment_base').prop('value', 'Submit comment');
    });
  };

  const bindPersonalControls = function bindPersonalControls() {
    if (!($('[data-current-member]').length > 0)) { return; }
    if (!($('[data-author]').length > 0)) { return; }
    const current = $('[data-current-member]').data('current-member');
    $('[data-author]').not('.control-bound').each((i, elm) => {
      $(elm).addClass('control-bound');
      if ($(elm).data('author') === current) {
        bindEditComment(elm);
        bindDeleteComment(elm);
      }
    });
  };

  const bindSubmit = function bindSubmit() {
    $('#new_comment').on('ajax:complete', () => {
      $('[data-comment-modal]').hide();
      $.reload('#comments', () => $('html').trigger('consumer:comments:refreshed'), new Date().getTime());
    });
  };

  bindCreateComment();
  bindPersonalControls();
  bindSubmit();
  bindReplyComment();
  $('[data-more-comments]').show();

  $('html').on('consumer:comments:refreshed', () => {
    bindCreateComment();
    bindPersonalControls();
    bindSubmit();
    bindReplyComment();
    bindShowMore();
    if (create) {
      show();
    }
  });
});
