/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(function() {

  $('.js-comparable--slider').slideReveal({
    trigger: $(".js-comparable--slider-button"),
    position: "right",
    push: false
  });

  return $('.compare-suggested-checkbox').click(function(e) {
    $('#compare-remove--' + $(e.target).prop('id').match(/\d+/g)[0]).trigger('click');
    $('.js-comparable--slider-compare-button').removeClass('disabled');
  });
});


$('.js-comparable--slider-compare-button').click(function(e) {
  return $(e.target).addClass('compare-loading').attr('value', '');
});
