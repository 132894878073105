/* eslint-disable no-undef */
// This is a very important piece of Javascript
//
// This runs on every single "page", blocking document "ready" (and so, pretty much all JS) until it's completed.

// It's job is to contact the session_ping#index controller action, and then decorate the page
// with any user-specific / session-specific content, like member names, flash messages, and authneticity tokens.
//
// The response it uses looks like this:
//
// {
//   "name":"Consumer staff",
//   "authenticity_token":"6Cbd5KmKASCfY3vTlegbIb1gsoX/J330lq12xjZTSQh4jHd4nsckle/H2LYQV3UV5dJPPCxswA5BzieUXcIXdw==",
//   "flash":"",
//   "id":10015002,
//   "google_analytics_events":""
// }
//
// The purpose of this is to allow user-specific features to work on pages that are fully cached
// in a reverse proxy (i.e. Fastly). Fastly cache entries Vary by member tier, but for anything completely specific
// to an individuals session or member account, this Javascript is here to shim those features in.
try {
  const MAX_ATTEMPTS = 2;
  const source = $('meta[name=authenticity-token-source]');
  const src = source.attr('content');
  // Attempts due to request error, not mismatched tier caching
  let attempts = 0;

  const sendRequest = () => {
    attempts += 1;
    $.ajax({
      url: src,
      cache: false,
      dataType: 'json',
      contentType: 'application/json; charset=utf-8'
    })
      .done(handleDone)
      .fail(handleFail);
  };

  const retry = () => {
    if (attempts < MAX_ATTEMPTS) {
      setTimeout(sendRequest, 3000);
    }
  };

  const handleDone = (data) => {
    const reloadedAlready = (window.location.href.substr(-2) === '?r');
    const token = $('meta[name=csrf-token]');
    const flash = $('#flash-shiv');
    const member = $('[data-current-member]');
    const hiMemberLink = $('.js-header-member-greeting');
    const body = $('body');

    try {
      // Ensure global authenticity token is set.
      token.attr('content', data.authenticity_token);

      if (reloadedAlready || data.matching_login_status) {
        if (reloadedAlready) {
          console.debug('have been redirected');
          console.debug(data);
          window.history.replaceState({}, document.title, window.location.pathname);
        }

        // Insert flash message html, if any.
        flash.replaceWith(data.flash);

        // Set any data attributes that use member id, and place in the body and window
        member.attr('data-current-member', data.id);
        window.currentMemberID = data.id;
        body.data('current-member', data.id);

        // Set the member's name in the topbar
        hiMemberLink.text(`Hi ${data.name}`);

        $('body').data('latestAuthToken', data.authenticity_token);

        // Ensure authenticity token is always sent in ajax requests.
        $.ajaxSetup({
          beforeSend(xhr, settings) {
            if (!settings.crossDomain) {
              xhr.setRequestHeader('X-CSRF-Token', data.authenticity_token);
            }
          }
        });

        // Ensure authenticity token is always set in forms.
        $('form input[name=authenticity_token]').attr('value', data.authenticity_token);

        const ga_s = document.createElement('script');
        ga_s.type = 'text/javascript';
        ga_s.innerHTML = data.google_analytics_events;
        $('head').append(ga_s);

        // Trigger a custom event to delay the loading of certain JS elements
        $('html').trigger('session:pinged');

        if (attempts > 1) {
          throw new Error('Session ping retry succeeded');
        }
      } else {
        // Reload the page if we're seeing the logged out body class while ping is
        // claiming we're logged in.
        console.debug('redirection being triggered - mismatch found in login status');
        console.debug(data);
        window.location = `${window.location.href}?r`;
      }
    } catch (e) {
      rg4js('send', { error: e, customData: { data, attempts } });
      retry();
    }
  };

  const handleFail = (jqXHR, textStatus, errorThrown) => {
    try {
      if (attempts > 1) {
        throw new Error(`Session ping retry failed, status: ${jqXHR && jqXHR.status}`);
      } else {
        throw new Error(`Session ping failed, status: ${jqXHR && jqXHR.status}`);
      }
    } catch (e) {
      rg4js('send', {
        error: e,
        customData: {
          jqXHR, textStatus, errorThrown, attempts
        }
      });
    }

    retry();
  };

  if (src) {
    sendRequest();
  } else if (!$('.devise')) {
    // ignore on devise pages
    throw new Error(`authenticity-token-source is missing: ${window.location.href}`);
  }
} catch (e) {
  rg4js('send', e);
}
