/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(function() {
  if ($('[data-favourites]').length <= 0) { return; }

  const setFavourited = (list_title, url, id) => {
    $('[data-favourite-menu-control]').text('Saved');
    $('[data-favourites]').addClass('starred');

    $(`[data-favourites-list-title='${list_title}'][data-favourite-url='${url}']`).parent().addClass('active-fav');
    $(`[data-favourites-list-title='${list_title}'][data-favourite-url='${url}']`).hide();
    $(`[data-defavourite-list-title='${list_title}'][data-defavourite-url='${url}']`).show();
    return $(`[data-defavourite-list-title='${list_title}'][data-defavourite-url='${url}']`).data('defavourite-path', '/member/bookmarks/' + id);
  };

  const setUnfavourited = (list_title, url) => {
    $(`[data-favourites-list-title='${list_title}'][data-favourite-url='${url}']`).parent().removeClass('active-fav');
    $(`[data-favourites-list-title='${list_title}'][data-favourite-url='${url}']`).show();
    $(`[data-defavourite-list-title='${list_title}'][data-defavourite-url='${url}']`).hide();
    return setFavourites();
  };

  const setUnfavouritedDropdown = () => {
    $('[data-favourite-menu-control]').text('Add to favourites');
    return $('[data-favourites]').removeClass('starred');
  };

  var setFavourites = () => {
    return $.getJSON($('[data-favourites]').data('favourites-index-path'), function(data) {
      let found = false;
      for (let b of data) {
        if (b.bookmark_list && (b.bookmarkable_id === $('[data-favourite]').data('bookmarkable-id')) && (b.url === $('[data-favourite]').data('favourite-url'))) {
          setFavourited(b.bookmark_list.title, b.url, b.id);
          found = true;
        }
      }
      if (!found) {
        return setUnfavouritedDropdown();
      }
    });
  };

  const setFavouriteLists = () => {
    return $.ajax({
      type: 'GET',
      url: $('[data-favourites]').data('favourites-list-index-path'),
      data: {
        bookmarkable_id: $('[data-favourites]').data('bookmarkable-id').toString(),
        bookmarkable_type: $('[data-favourites]').data('bookmarkable-type').toString()
      },
      success(response) {
        $('.favourites-select-options-cat').append(response);
        setFavourites();
        bindFavourite();
        return bindDefavourite();
      }
    });
  };

  const bindDocumentClick = () => {
    return $(document).click(function(e) {
      $('[data-favourite-menu]').hide();
      return hideForm();
    });
  };

  const bindFavouriteMenu = () => {
    return $('[data-favourite-menu]').click(function(e) {
      e.stopPropagation();
      $('form#new_bookmark_list').hide();
      return $('.favourites-select-options-new-create').show();
    });
  };

  const bindFavouriteMenuControl = () => {
    return $('[data-favourite-menu-control]').click(function(e) {
      e.stopPropagation();

      if ($('[data-favourite-menu]').is(':visible')) {
        $('form#new_bookmark_list').hide();
        $('.favourites-select-options-new-create').show();
        return $('[data-favourite-menu]').hide();
      } else {
        return $('[data-favourite-menu]').show();
      }
    });
  };

  var bindDefavourite = () => {
    return $('[data-defavourite]').click(function(e) {
      e.stopPropagation();
      hideForm();

      const defav_list_title = $(this).data('defavourite-list-title');
      const defav_url = $(this).data('defavourite-url');
      const defav = $.ajax({type: 'DELETE', url: $(this).data('defavourite-path')});

      return defav.done(data => setUnfavourited(defav_list_title, defav_url));
    });
  };

  var bindFavourite = () => {
    return $('[data-favourite]').click(function(e) {
      e.stopPropagation();
      hideForm();

      const fav = $.post($(this).data('favourites-path'), {
            'bookmark[url]': $(this).data('favourite-url'),
            'bookmark[title]': $(this).data('favourite-title'),
            'bookmark[bookmark_list_id]': $(this).data('favourites-list-id'),
            'bookmark_list_title': $(this).data('favourites-list-title')
          }
      );

      return fav.done(data => setFavourited(data.bookmark_list.title, data.url, data.id));
    });
  };

  const bindShow = () => {
    return $('.favourites-select-options-new-create').click(function(e) {
      e.stopPropagation();
      $(this).hide();
      $('form#new_bookmark_list').show();
      return $('input#bookmark_list_title').focus();
    });
  };

  const bindSubmit = () => {
    $('form#new_bookmark_list').click(e => e.stopPropagation());

    return $('form#new_bookmark_list').on('submit', () => hideForm());
  };

  var hideForm = () => {
    $('form#new_bookmark_list').hide();
    return $('.favourites-select-options-new-create').show();
  };

  setFavouriteLists();
  bindDocumentClick();
  bindFavouriteMenu();
  bindFavouriteMenuControl();
  bindShow();
  bindSubmit();

  return $('html').on('consumer:favourites:refreshed', function() {
    setFavouriteLists();
    bindFavouriteMenu();
    bindShow();
    return bindSubmit();
  });
});
