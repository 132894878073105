/*jslint unparam: true, browser: true, indent: 2 */

module.exports = function init() { (function ($, window, document, undefined) {
    'use strict';

    Foundation.libs.alerts = {
      name : 'alerts',

      version : '4.3.2',

      settings : {
        animation: 'fadeOut',
        speed: 300, // fade out speed
        callback: function (){}
      },

      init : function (scope, method, options) {
        this.scope = scope || this.scope;
        Foundation.inherit(this, 'data_options');

        if (typeof method === 'object') {
          $.extend(true, this.settings, method);
        }

        if (typeof method !== 'string') {
          if (!this.settings.init) { this.events(); }

          return this.settings.init;
        } else {
          return this[method].call(this, options);
        }
      },

      events : function () {
        var self = this;

        $(this.scope).on('click.fndtn.alerts', '[data-alert] a.close', function (e) {
            var alertBox = $(this).closest("[data-alert]"),
                settings = $.extend({}, self.settings, self.data_options(alertBox));

          e.preventDefault();
          alertBox[settings.animation](settings.speed, function () {
            $(this).remove();
            settings.callback();
          });
        });

        this.settings.init = true;
      },

      off : function () {
        $(this.scope).off('.fndtn.alerts');
      },

      reflow : function () {}
    };
  }(Foundation.zj, this, this.document));
}
