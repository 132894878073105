/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(function() {
  var wrapper = null;

  var clearAddress = function clearAddress() {
    return wrapper.find(".address_address1, .address_address2, .address_address3, .address_suburb, .address_city, .address_postcode, .address_region").val('');
  };

  $.fn.addressFinder = function() {

    return $(this).each(function() {
      if ($(this).find('.address_control')[0] || $(this).find('.address_address1')[0]) {
        var controlClass;
        if ($(this).find('.address_control')[0]) {
          controlClass = 'address_control';
        } else {
          controlClass = 'address_address1';
        }

        window.addressFinder = new (AddressFinder.Widget)(
          document.getElementsByClassName(controlClass)[0],
          window.consumerAddressFinderKey, 'NZ',
          {empty_content: "You must select an address from the list. If your address is not displayed or you are not in New Zealand, please <a href='/contact-us'>contact us</a> to purchase your membership."}
        );

        wrapper = $(this);
        var address_control = $(wrapper.find('.address_control'));

        address_control.on("keydown", function(e) {
          // character, backspace, delete
          if ((e.key.length === 1) || (e.keyCode === 8) || (e.keyCode === 46)) {
            return clearAddress();
          }
        });

        address_control.on("blur", function(e) {
          if (address_control.val().length > 0) {
            var validAddress = true; 

            if (!$(wrapper.find(".address_address1")).val().length > 0) { validAddress = false; }

            wrapper.find(".address_address1, .address_address2, .address_address3, .address_suburb, .address_city, .address_postcode, .address_region").each(function() {
              if (!address_control.val().includes($(this).val())) { return validAddress = false; }
            });

            if (!validAddress) {
              clearAddress();
              return address_control.val('');
            }
          } else {
            return clearAddress();
          }
        });

        addressFinder.on("result:select", function(value, item) {
          wrapper.find(".address_control, .address_address1, .address_address2, .address_address3, .address_suburb, .address_city, .address_postcode, .address_region").val("").trigger('change');

          var address = item.postal || item.a;
          var addressLines = address.split(",");

          for (var i = 0; i < addressLines.length; i++) {
            var line = addressLines[i];
            addressLines[i] = addressLines[i].trim();
          }

          var city = item.mailtown || item.city;
          if (addressLines[addressLines.length-1] === (city + " " + item.postcode)) {
            addressLines.pop();
            wrapper.find(".address_city").val(city).trigger('change');
            wrapper.find(".address_postcode").val(item.postcode).trigger('change');
          }

          var suburb = item.post_suburb || item.suburb;
          if (addressLines[addressLines.length-1] === suburb) {
            addressLines.pop();
            wrapper.find(".address_suburb").val(suburb).trigger('change');
          }

          if (addressLines.length > 2) {
            wrapper.find(".address_address3").val(addressLines.pop()).trigger('change');
          }

          if (addressLines.length > 1) {
            wrapper.find(".address_address2").val(addressLines.pop()).trigger('change');
          }

          wrapper.find(".address_address1").val(addressLines.join(", ")).trigger('change');

          return wrapper.find(".address_control").val(address).trigger('change');
        });

        if (wrapper.find('select.address_country').find('option:selected').val() !== 'New Zealand') {
          addressFinder.disable();
        } else {
          wrapper.find('#region').addClass('js-dn');
          wrapper.find('.address').attr('readonly', 'readonly');
        }

        return wrapper.find('select.address_country').change(function() {
          if ($(this).find('option:selected').val() === 'New Zealand') {
            wrapper.find('#region').addClass('js-dn');
            wrapper.find('.address_region').val('').trigger('change');
            wrapper.find('.address').attr('readonly', 'readonly');
            return addressFinder.enable();
          } else {
            wrapper.find('#region').removeClass('js-dn');
            wrapper.find('.address').removeAttr('readonly');
            return addressFinder.disable();
          }
        });
      }
    });
  };


  $('.js-address-form').addressFinder();
  $('.af_list').addClass($('.js-address-form').data('listClass'));
});
