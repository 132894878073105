/*jslint unparam: true, browser: true, indent: 2 */

module.exports = function init() { (function ($, window, document, undefined) {
    'use strict';

    Foundation.libs.reveal = {
      name : 'reveal',

      version : '4.3.2',

      locked : false,

      settings : {
        animation: 'fadeAndPop',
        animationSpeed: 250,
        closeOnBackgroundClick: true,
        closeOnEsc: true,
        dismissModalClass: 'close-reveal-modal',
        bgClass: 'reveal-modal-bg',
        open: function(){},
        opened: function(){},
        close: function(){},
        closed: function(){},
        bg : $('.reveal-modal-bg'),
        css : {
          open : {
            'opacity': 0,
            'visibility': 'visible',
            'display' : 'block'
          },
          close : {
            'opacity': 1,
            'visibility': 'hidden',
            'display': 'none'
          }
        }
      },

      init : function (scope, method, options) {
        Foundation.inherit(this, 'data_options delay');

        if (typeof method === 'object') {
          $.extend(true, this.settings, method);
        } else if (typeof options !== 'undefined') {
          $.extend(true, this.settings, options);
        }

        if (typeof method !== 'string') {
          this.events();

          return this.settings.init;
        } else {
          return this[method].call(this, options);
        }
      },

      events : function () {
        var self = this;

        $(this.scope)
          .off('.fndtn.reveal')
          .on('click.fndtn.reveal', '[data-reveal-id]', function (e) {
            e.preventDefault();

            if (!self.locked) {
              var element = $(this),
                  ajax = element.data('reveal-ajax');

              self.locked = true;

              if (typeof ajax === 'undefined') {
                self.open.call(self, element);
              } else {
                var url = ajax === true ? element.attr('href') : ajax;

                self.open.call(self, element, {url: url});
              }
            }
          })
          .on('click.fndtn.reveal touchend', this.close_targets(), function (e) {
            e.preventDefault();
            if (!self.locked) {
              var settings = $.extend({}, self.settings, self.data_options($('.reveal-modal.open'))),
                bgClicked = $(e.target)[0] === $('.' + settings.bgClass)[0];
              if (bgClicked && !settings.closeOnBackgroundClick) {
                return;
              }

              self.locked = true;
              self.close.call(self, bgClicked ? $('.reveal-modal.open') : $(this).closest('.reveal-modal'));
            }
          });

        if($(this.scope).hasClass('reveal-modal')) {
          $(this.scope)
            .on('open.fndtn.reveal', this.settings.open)
            .on('opened.fndtn.reveal', this.settings.opened)
            .on('opened.fndtn.reveal', this.open_video)
            .on('close.fndtn.reveal', this.settings.close)
            .on('closed.fndtn.reveal', this.settings.closed)
            .on('closed.fndtn.reveal', this.close_video);
        } else {
          $(this.scope)
            .on('open.fndtn.reveal', '.reveal-modal', this.settings.open)
            .on('opened.fndtn.reveal', '.reveal-modal', this.settings.opened)
            .on('opened.fndtn.reveal', '.reveal-modal', this.open_video)
            .on('close.fndtn.reveal', '.reveal-modal', this.settings.close)
            .on('closed.fndtn.reveal', '.reveal-modal', this.settings.closed)
            .on('closed.fndtn.reveal', '.reveal-modal', this.close_video);
        }

        $( 'body' ).bind( 'keyup.reveal', function ( event ) {
          var open_modal = $('.reveal-modal.open'),
              settings = $.extend({}, self.settings, self.data_options(open_modal));
          if ( event.which === 27  && settings.closeOnEsc) { // 27 is the keycode for the Escape key
            open_modal.foundation('reveal', 'close');
          }
        });

        return true;
      },

      open : function (target, ajax_settings) {
        if (target) {
          if (typeof target.selector !== 'undefined') {
            var modal = $('#' + target.data('reveal-id'));
          } else {
            var modal = $(this.scope);

            ajax_settings = target;
          }
        } else {
          var modal = $(this.scope);
        }

        if (!modal.hasClass('open')) {
          var open_modal = $('.reveal-modal.open');

          if (typeof modal.data('css-top') === 'undefined') {
            modal.data('css-top', parseInt(modal.css('top'), 10))
              .data('offset', this.cache_offset(modal));
          }

          modal.trigger('open');

          if (open_modal.length < 1) {
            this.toggle_bg();
          }

          if (typeof ajax_settings === 'undefined' || !ajax_settings.url) {
            this.hide(open_modal, this.settings.css.close);
            this.show(modal, this.settings.css.open);
          } else {
            var self = this,
                old_success = typeof ajax_settings.success !== 'undefined' ? ajax_settings.success : null;

            $.extend(ajax_settings, {
              success: function (data, textStatus, jqXHR) {
                if ( $.isFunction(old_success) ) {
                  old_success(data, textStatus, jqXHR);
                }

                modal.html(data);
                $(modal).foundation('section', 'reflow');

                self.hide(open_modal, self.settings.css.close);
                self.show(modal, self.settings.css.open);
              }
            });

            $.ajax(ajax_settings);
          }
        }
      },

      close : function (modal) {

        var modal = modal && modal.length ? modal : $(this.scope),
            open_modals = $('.reveal-modal.open');

        if (open_modals.length > 0) {
          this.locked = true;
          modal.trigger('close');
          this.toggle_bg();
          this.hide(open_modals, this.settings.css.close);
        }
      },

      close_targets : function () {
        var base = '.' + this.settings.dismissModalClass;

        if (this.settings.closeOnBackgroundClick) {
          return base + ', .' + this.settings.bgClass;
        }

        return base;
      },

      toggle_bg : function () {
        if ($('.' + this.settings.bgClass).length === 0) {
          this.settings.bg = $('<div />', {'class': this.settings.bgClass})
            .appendTo('body');
        }

        if (this.settings.bg.filter(':visible').length > 0) {
          this.hide(this.settings.bg);
        } else {
          this.show(this.settings.bg);
        }
      },

      show : function (el, css) {
        // is modal
        if (css) {
          if (el.parent('body').length === 0) {
            var placeholder = el.wrap('<div style="display: none;" />').parent();
            el.on('closed.fndtn.reveal.wrapped', function() {
              el.detach().appendTo(placeholder);
              el.unwrap().unbind('closed.fndtn.reveal.wrapped');
            });

            el.detach().appendTo('body');
          }

          if (/pop/i.test(this.settings.animation)) {
            css.top = $(window).scrollTop() - el.data('offset') + 'px';
            var end_css = {
              top: $(window).scrollTop() + el.data('css-top') + 'px',
              opacity: 1
            };

            return this.delay(function () {
              return el
                .css(css)
                .animate(end_css, this.settings.animationSpeed, 'linear', function () {
                  this.locked = false;
                  el.trigger('opened');
                }.bind(this))
                .addClass('open');
            }.bind(this), this.settings.animationSpeed / 2);
          }

          if (/fade/i.test(this.settings.animation)) {
            var end_css = {opacity: 1};

            return this.delay(function () {
              return el
                .css(css)
                .animate(end_css, this.settings.animationSpeed, 'linear', function () {
                  this.locked = false;
                  el.trigger('opened');
                }.bind(this))
                .addClass('open');
            }.bind(this), this.settings.animationSpeed / 2);
          }

          return el.css(css).show().css({opacity: 1}).addClass('open').trigger('opened');
        }

        // should we animate the background?
        if (/fade/i.test(this.settings.animation)) {
          return el.fadeIn(this.settings.animationSpeed / 2);
        }

        return el.show();
      },

      hide : function (el, css) {
        // is modal
        if (css) {
          if (/pop/i.test(this.settings.animation)) {
            var end_css = {
              top: - $(window).scrollTop() - el.data('offset') + 'px',
              opacity: 0
            };

            return this.delay(function () {
              return el
                .animate(end_css, this.settings.animationSpeed, 'linear', function () {
                  this.locked = false;
                  el.css(css).trigger('closed');
                }.bind(this))
                .removeClass('open');
            }.bind(this), this.settings.animationSpeed / 2);
          }

          if (/fade/i.test(this.settings.animation)) {
            var end_css = {opacity: 0};

            return this.delay(function () {
              return el
                .animate(end_css, this.settings.animationSpeed, 'linear', function () {
                  this.locked = false;
                  el.css(css).trigger('closed');
                }.bind(this))
                .removeClass('open');
            }.bind(this), this.settings.animationSpeed / 2);
          }

          return el.hide().css(css).removeClass('open').trigger('closed');
        }

        // should we animate the background?
        if (/fade/i.test(this.settings.animation)) {
          return el.fadeOut(this.settings.animationSpeed / 2);
        }

        return el.hide();
      },

      close_video : function (e) {
        var video = $(this).find('.flex-video'),
            iframe = video.find('iframe');

        if (iframe.length > 0) {
          iframe.attr('data-src', iframe[0].src);
          iframe.attr('src', 'about:blank');
          video.hide();
        }
      },

      open_video : function (e) {
        var video = $(this).find('.flex-video'),
            iframe = video.find('iframe');

        if (iframe.length > 0) {
          var data_src = iframe.attr('data-src');
          if (typeof data_src === 'string') {
            iframe[0].src = iframe.attr('data-src');
          } else {
            var src = iframe[0].src;
            iframe[0].src = undefined;
            iframe[0].src = src;
          }
          video.show();
        }
      },

      cache_offset : function (modal) {
        var offset = modal.show().height() + parseInt(modal.css('top'), 10);

        modal.hide();

        return offset;
      },

      off : function () {
        $(this.scope).off('.fndtn.reveal');
      },

      reflow : function () {}
    };
  }(Foundation.zj, this, this.document));
}