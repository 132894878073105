/*
 * decaffeinate suggestions:
 * DS103: Rewrite code to no longer use __guard__
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(function() {
  $('form[data-remote=true]').on('ajax:error', function(event, xhr, status) {
    // Parse error messages from JSON
    var responseJSON = JSON.parse(xhr.responseText);
    var errors = responseJSON.errors || responseJSON;

    $(this).clearErrors();
    $(this).applyValidationErrors(errors);

    // Scroll to error message
    if ($('.invalid').length > 0) {
      $.scrollTo('.invalid', -100, 1000);
    }
  });
});


$.fn.emptyPasswordFields = function() {
  $(this).find('input[type="password"]').val('');
};

$.fn.clearErrors = function() {
  $(this).find('.field-error').remove();
  $(this).find('.global-error').text('').removeClass('global-error').addClass('global-error-placeholder');
  $(this).find('.invalid').removeClass('invalid');
  $(this).find('[data-disable-with]').each(function() {
    var obj = $(this).val($(this).data('original'));
    if (typeof obj.enable === 'function') { obj.enable() }
  });
  __guardMethod__($(this).find('.enable-after-ajax'), 'enable', function(o) {
    o.enable();
  });
  if ($(this).hasClass('enable-after-ajax')) { __guardMethod__($(this), 'enable', function(o1) {
    o1.enable();
  }); }
  __guardMethod__($(this), 'clearPreservedVal', function(o2) {
    o2.clearPreservedVal('sinceError');
  });
};

$.fn.applyValidationErrors = function(errors) {
  var _this = this;
  var object_name = $(this).attr('id').replace(/^(new_|edit_)/, '').replace(/_\d+$/, '');

  // Iterate through the errors, try and find an input, and
  // add error
  for (var field_name in errors) {
    var field_errors = errors[field_name];
    field_name = field_name.replace(/\./g, '_attributes_'); // to handle fields_for

    let field = $(_this).find(`#${object_name}_${field_name}`);
    field.addError(field_errors.join(', '), field_name);
  }
};

$.fn.addError = function(field_errors, field_name, field_label) {
  var error_text;
  if (field_name == null) { field_name = ''; }
  var input = $(this);
  input.removeError();
  if (field_label == null) {
    let errorPrefixLabel = input.data("error-prefix");
    let inputFieldLabel = $(`[for='${input.attr('id')}']`)

    if (errorPrefixLabel) {
      field_label = errorPrefixLabel;
    } else if (inputFieldLabel[0] != null) {
      field_label = inputFieldLabel.first().text();
    } else {
      // Remove the underscores from the field name
      field_label = field_name.replace(/_/g, ' ');
      // Capitalize the first letter of the field name
      field_label = field_label.charAt(0).toUpperCase() + field_label.slice(1);
    }
  }

  // Create the error message
  if ((field_label === '') || (field_label === 'Base')) {
    error_text = field_errors;
  } else {
    error_text = `${field_label} ${field_errors}`;
  }

  input.addClass('invalid');
  input.preserveVal('sinceError');

  var append = field_label !== 'Base' ? $("<div />", {"class": 'arrow'}) : '';

  var errorCallout = $("<div />", {
    "class": 'field-error',
    text: error_text
  }).append(append);

  input.closest('.accordion section').addClass('active');
  if (input.parent().is('.radio,.checkbox,.select')) {
    errorCallout.insertAfter(input.parent());
  } else {
    errorCallout.insertAfter(input);
  }
};

$('body').on('change', '.invalid', function() {
  var that = this;
  if (!$(that).isPreservedVal('sinceError')) { $(that).removeError(); }
});

$.fn.removeError = function() {
  $(this).each(function() {
    var input = $(this);
    if (input.parent().is('.radio,.checkbox,.select')) {
      input.parent().next('.field-error').remove();
    } else {
      input.next('.field-error').remove();
    }
    input.removeClass('invalid');
  });
};

function __guardMethod__(obj, methodName, transform) {
  if (typeof obj !== 'undefined' && obj !== null && typeof obj[methodName] === 'function') {
    return transform(obj, methodName);
  } else {
    return undefined;
  }
}