/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-webpack-loader-syntax */
// external libraries
require('expose-loader?$!jquery');
require('expose-loader?jQuery!jquery');
require('expose-loader?algoliasearch!algoliasearch');
require('expose-loader?select2!select2');
require('expose-loader?bootstrap!bootstrap');

window.jQuery = $;
window.$ = $;

require('jquery-ujs');
require('jquery-ui-sortable-npm');

// Sorts auth token, flash and other non-cacheable common elements
require('@app/javascripts/session_ping.js');

$.ajaxSetup({
  beforeSend(xhr) {
    xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
  }
});

require('@vendor/javascripts/foundation/index.js');
require('@vendor/javascripts/autocomplete.jquery.js');
require('@vendor/javascripts/bootstrap_fileupload.js');
require('@vendor/javascripts/jquery.clickoutside.js');
require('@vendor/javascripts/jquery.payment.js');
require('@vendor/javascripts/jquery.placeholder.js');
require('@vendor/javascripts/jquery.slidereveal.js');
require('@vendor/javascripts/jquery.tagsinput.js');
require('@vendor/javascripts/jquery.validate.js');
require('@vendor/javascripts/jquery-ui.min.js');
require('@vendor/javascripts/markdown.converter.js');
require('@vendor/javascripts/markdown.editor.js');
require('@vendor/javascripts/owl.carousel.js');

// // vendor js needing to be exposed on global object
require('expose-loader?ES6Promise!@vendor/javascripts/polyfills/es6-promise.min.js');
require('expose-loader?reqwest!@vendor/javascripts/reqwest.min.js');
require('expose-loader?NeatComplete!@vendor/javascripts/neat_complete.min.js');

let webpackContext = require.context('@app/javascripts/utilities/');
for (const key of webpackContext.keys()) { webpackContext(key); }

webpackContext = require.context('@app/javascripts/components/');
for (const key of webpackContext.keys()) { webpackContext(key); }

webpackContext = require.context('@app/javascripts/modules/');
for (const key of webpackContext.keys()) { webpackContext(key); }

webpackContext = require.context('@app/javascripts/pages/');
for (const key of webpackContext.keys()) { webpackContext(key); }

require('@app/javascripts/setup.js');

$(() => $('html').trigger('consumer.dependencies.loaded'));
